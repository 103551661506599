import React, { useEffect, useState, useContext } from "react";
import styles from "./Enrich.module.css";
import Topnav from "../../Components/Topnav/Topnav";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  InboxOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import Papa from "papaparse";
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import { saveAs as saveFile } from "file-saver";
import {
  db,
  auth,
  saveCSVFileForEnrichment,
  fetchSavedCSVFileListForEnrichment,
  fetchSavedCSVFileRows,
  deleteSavedCSVFile,
  getCredits,
  updateSessionCookie,
} from "../../firebase-config";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { isValid, removeParamsFromUrl } from "../../Utils/list.js";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Modal,
  Tag,
  Spin,
  Table,
  Form,
  Upload,
  Input,
  Select,
  message,
  Switch,
  Tooltip
} from "antd";
import { UserIdContext } from "../../Context/UserIdContext";
import { CreditCountContext } from "../../Context/CreditCountContext";
import { v4 as uuidv4 } from 'uuid';
import { waitFor } from "../../Utils/utils";

var accessToken = "";
var currentCredit = 0;

export default function Enrich(props) {
  //misc states
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firebaseAuthUUID, setFirebaseAuthUUID] = useState("");
  const [UserId, setUserId] = useContext(UserIdContext);
  const { CreditCount, setCreditCount } = useContext(CreditCountContext);
  const { AICreditCount, setAICreditCount } = useContext(CreditCountContext);
  const [rawCSVFileObjectList, setRawCSVFileObjectList] = useState([]);
  const [csvHeadersToSelect, setCsvHeadersToSelect] = useState([]);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataUploadModalVisible, setDataUploadModalVisible] = useState(false);
  const [savedCSVFileList, setSavedCSVFileList] = useState([]);
  const [icebreakerToggleStatus, setIcebreakerToggleStatus] = useState(false);
  // const [accessToken, setAccessToken] = useState("");

  let navigate = useNavigate();
  const { Dragger } = Upload;
  const { Option } = Select;

  const [uploadCSVForEnrichmentForm] = Form.useForm();

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      //console.log("user logged in");

      //fetch and saved firebase auth access token
      await user.getIdToken().then((token) => {
        // setAccessToken(token);
        accessToken = token;
        //console.log(token);
      });

      setLoggedInUser(user);
      setFirebaseAuthUUID(user.uid);
      setUserId(user.uid);

      onSnapshot(doc(db, "users", `${user.uid}`), (doc) => {
        //console.log("Current data: ", doc.data());
        currentCredit = doc.data().credits;
        setCreditCount(currentCredit);
        //console.log("credit Count", currentCredit);
      });
    } else {
      console.log("user not logged in");
      navigate("/login");
    }
  });

  const refreshSalesnavSession = async (record) => {
    var data = { messageType: "refresh-session", text: "Hello from the webpage!" };

    // send message to extension to refresh session
    window.postMessage(data, "*")

    let fileId = record.id;

    // wait for response from extension 
    // this promise based function required to avoid re-rendering of message component
    function waitForMessage() {
      return new Promise(function (resolve, reject) {
        // Listen for the message
        window.addEventListener("message", function (event) {
          // We only accept messages from ourselves
          if (event.source != window)
            return;

          // Check that the message is in the format we expect
          if (event.data.messageType && (event.data.messageType == "session-query-result")) {
            let { status, value } = event.data.payload;

            // resolve the promise with the message
            resolve({value, status});
          }
        });
      });
    }

    // wait for message from extension
    waitForMessage().then(async function(res) {
      console.log("Message received from extension!!");
      await waitFor(2000);

      // open session message modal
      showSessionModal(res.status, res.value, fileId);
    });
  }

  const showSessionModal = async (status, value, fileId) => {
    if (status === "valid") {
      // update session cookie in firebase
      await updateSessionCookie(fileId, value)

      // get updated list of saved csv files
      getSavedCSVList();

      message.info("Session updated successfully");
    } else if (status === "invalid") {
      message.error("Failed to update session, please login to salesnav and try again");
    }
  };

  // enrichment table column schema
  const enrichmentListTableColumn = [
    {
      title: "File name",
      dataIndex: "fileName",
      key: "fileName",
      sorter: (a, b) => a.fileName.localeCompare(b.fileName),
      render: (record, index) => (
        <div
          style={{ display: "flex", flexDirection: "column", maxWidth: "90%" }}
        >
          {/* <Link to={`/track/company/${record}`}>{record}</Link> */}
          <h4>{record}</h4>
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (record, index) => (
        <>
          <Tag
            color={record.color}
            style={{
              fontWeight: "500",
              margin: "3px 3px 3px 0px",
            }}
          >
            {record.stage}
          </Tag>
        </>
      ),
    },

    {
      title: "Rows",
      dataIndex: "rowCount",
      key: "rowCount",
      render: (record, index) => (
        <div
          style={{ display: "flex", flexDirection: "column", maxWidth: "90%" }}
        >
          {/* <Link to={`/track/company/${record}`}>{record}</Link> */}
          <h4>{record}</h4>
        </div>
      ),
    },

    {
      title: "Uploaded",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      render: (record, index) => (
        <div
          style={{ display: "flex", flexDirection: "column", maxWidth: "90%" }}
        >
          <h4>{moment(record.toDate().toDateString()).format("LL")}</h4>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <div className={styles.actionButtonContainer}>
          <Tooltip title="Download CSV" key={uuidv4()}>
            <button
              className="primary-button"
              style={{ margin: "0px 5px" }}
              onClick={async (e) => {
                setLoading(true);

                let csvRawDataArray = await fetchSavedCSVFileRows(
                  UserId,
                  record.fileName
                );
                //filter raw data
                csvRawDataArray.forEach((row) => {
                  // break icebreaker objects into seperate key value pairs
                  row["awardLine"] = row["iceBreaker"]?.awardLine;
                  row["cityRandomFactLine"] = row["iceBreaker"]?.cityRandomFactLine;
                  row["cityRestaurantLine"] = row["iceBreaker"]?.cityRestaurantLine;
                  row["citySportsLine"] = row["iceBreaker"]?.citySportsLine;
                  row["companyLinkedInPageUrl"] = row["iceBreaker"]?.companyLinkedInPageUrl || row["companyLinkedInPageUrl"];
                  row["companyName"] = row["iceBreaker"]?.companyName || row["companyName"];
                  row["emailAddress"] = row["iceBreaker"]?.emailAddress;
                  row["emailIcebreaker"] = row["iceBreaker"]?.emailIcebreaker;
                  row["firstName"] = row["iceBreaker"]?.firstName || row["firstName"];
                  row["fullEmail"] = row["iceBreaker"]?.fullEmail;
                  row["fullEmailBody"] = row["iceBreaker"]?.fullEmailBody;
                  row["hardcodedCTA1"] = row["iceBreaker"]?.hardcodedCTA1;
                  row["hardcodedCTA2"] = row["iceBreaker"]?.hardcodedCTA2;
                  row["hardcodedCTA3"] = row["iceBreaker"]?.hardcodedCTA3;
                  row["hardcodedCTA4"] = row["iceBreaker"]?.hardcodedCTA4;
                  row["hardcodedCTA5"] = row["iceBreaker"]?.hardcodedCTA5;
                  row["hardcodedCTA6"] = row["iceBreaker"]?.hardcodedCTA6;
                  row["hardcodedCTA7"] = row["iceBreaker"]?.hardcodedCTA7;
                  row["hardcodedCTA8"] = row["iceBreaker"]?.hardcodedCTA8;
                  row["helloLine"] = row["iceBreaker"]?.helloLine;
                  row["lastName"] = row["iceBreaker"]?.lastName || row["lastName"];
                  row["phoneCompany"] = row["iceBreaker"]?.phoneCompany;
                  row["phoneDirect"] = row["iceBreaker"]?.phoneDirect;
                  row["phoneMobile"] = row["iceBreaker"]?.phoneMobile;
                  row["phoneNumbers"] = row["iceBreaker"]?.phoneNumbers;
                  row["psAwardLine"] = row["iceBreaker"]?.psAwardLine;
                  row["psCheerLine"] = row["iceBreaker"]?.psCheerLine;
                  row["psCityRestaurantLine"] = row["iceBreaker"]?.psCityRestaurantLine;
                  row["psCitySportsLine"] = row["iceBreaker"]?.psCitySportsLine;
                  row["psPublicationLine"] = row["iceBreaker"]?.psPublicationLine;
                  row["psRecommendationLine"] = row["iceBreaker"]?.psRecommendationLine;
                  row["psTransitionLine"] = row["iceBreaker"]?.psTransitionLine;
                  row["psVolunteerWorkLine"] = row["iceBreaker"]?.psVolunteerWorkLine;
                  row["publicationLine"] = row["iceBreaker"]?.publicationLine;
                  row["recommendationLine"] = row["iceBreaker"]?.recommendationLine;
                  row["selectedIcebreakerForEmail"] = row["iceBreaker"]?.selectedIcebreakerForEmail;
                  row["selectedIcebreakerLine"] = row["iceBreaker"]?.selectedIcebreakerLine;
                  row["transitionLine"] = row["iceBreaker"]?.transitionLine;
                  row["transitionLine1"] = row["iceBreaker"]?.transitionLine1;
                  row["transitionLine2"] = row["iceBreaker"]?.transitionLine2;
                  row["transitionLine3"] = row["iceBreaker"]?.transitionLine3;
                  row["transitionLine4"] = row["iceBreaker"]?.transitionLine4;
                  row["transitionLine5"] = row["iceBreaker"]?.transitionLine5;
                  row["transitionLine6"] = row["iceBreaker"]?.transitionLine6;
                  row["transitionLine7"] = row["iceBreaker"]?.transitionLine7;
                  row["transitionLine8"] = row["iceBreaker"]?.transitionLine8;
                  row["univAlumLine"] = row["iceBreaker"]?.univAlumLine;
                  row["univClubLine"] = row["iceBreaker"]?.univClubLine;
                  row["univRandomFactLine"] = row["iceBreaker"]?.univRandomFactLine;
                  row["univRestaurantLine"] = row["iceBreaker"]?.univRestaurantLine;
                  row["univSportsLine"] = row["iceBreaker"]?.univSportsLine;
                  row["volunteerworkLine"] = row["iceBreaker"]?.volunteerworkLine;

                  // remove backend specific data before downloading it as csv
                  delete row["firebase_auth_uuid"];
                  delete row["fileName"];
                  delete row["iceBreaker"];
                  delete row["enriched"];
                  delete row["scrapped"];
                });
                // console.log(csvRawDataArray);
                downloadCSV(csvRawDataArray, record.fileName);
                setLoading(false);
              }}
            >
              Download
            </button>
          </Tooltip>

          <Tooltip title="Delete CSV" key={uuidv4()}>

            <button
              className="secondary-button-inactive"
              onClick={async () => {
                confirmCSVFileDelete(record.fileName);
              }}
            >
              <DeleteOutlined />
            </button>
          </Tooltip>

          {
            record.Status.stage === "Session expired" && (

              <Tooltip title="Refresh Session" key={uuidv4()} onClick={() => refreshSalesnavSession(record)}>
                <button className="secondary-button-inactive">
                  <SyncOutlined />
                </button>
              </Tooltip>
            )
          }
        </div>
      ),
    },
  ];

  // takes a csv object-array & filename string and saves csv file on desktop
  function downloadCSV(csvArray, fileName) {
    var csv = Papa.unparse(csvArray);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveFile(csvData, `${fileName}`);
  }

  const checkForCreditChange = async () => {
    if (UserId === null || UserId === undefined || UserId === "") {
      return;
    }
    const { personalisationCredits } = await getCredits(UserId);

    if (personalisationCredits > 0) {
      const list = await fetchSavedCSVFileListForEnrichment(UserId);
      list.forEach(async (item) => {
        if (item.scrapComplete === true && item.enrichmentStatus === "Out of Credits") {
          const fileRef = doc(db, "enrichment_csv_file_list", item.id);
          await updateDoc(fileRef, {
            enrichmentStatus: "Enriching...",
          });
        }
      })
    }
  }

  useEffect(() => {
    getSavedCSVList();
  }, [UserId]);

  //fetches saves csv list
  const getSavedCSVList = async () => {
    setLoading(true);
    await checkForCreditChange(UserId);
    const list = await fetchSavedCSVFileListForEnrichment(UserId);

    list.forEach((item) => {
      let Status = {};

      // TODO: change this status para based on firebase doc status once chrome extension changes are deployed 
      if (item.enrichmentComplete === false && item.scrapComplete === false) {
        Status.stage = "Scrapping...";
        Status.color = "orange";
      }

      if (item.enrichmentStatus === "Enriching..." && item.scrapComplete === true) {
        Status.stage = item.enrichmentStatus;
        Status.color = "blue";
      }

      if (item.enrichmentStatus === "Out of Credits" && item.scrapComplete === true) {
        Status.stage = item.enrichmentStatus;
        Status.color = "red";
      }

      if (item.enrichmentStatus === "Enriched") {
        Status.stage = item.enrichmentStatus;
        Status.color = "green";
      }

      if (item.enrichmentStatus === "Session expired" && item.scrapComplete === false) {
        Status.stage = item.enrichmentStatus;
        Status.color = "red";
      }

      item.Status = Status;
    })
    setSavedCSVFileList(list);
    setLoading(false);
  };

  // delete saved csv file logic

  const deleteCSVFile = async (fileName) => {
    console.log("deleting csv", fileName);
    setLoading(true);
    await deleteSavedCSVFile(UserId, fileName);
    setLoading(false);
    await getSavedCSVList();
  };

  // delete csv file confirmation modal
  function confirmCSVFileDelete(fileName) {
    Modal.confirm({
      title: `Delete "${fileName}" ?`,
      content: (
        <div>
          <p>
            "{fileName}" will be deleted download enriched file before deleting
          </p>
        </div>
      ),
      onOk() {
        deleteCSVFile(fileName);
      },
    });
  }

  const fileUploaderProps = {
    multiple: false,

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log("file list", info.fileList);
        setRawCSVFileObjectList(info.fileList);
        let rawCSVFileObjectList = info.fileList;

        // parse & save available csv headers from csv file
        rawCSVFileObjectList.forEach((rawFile) => {
          Papa.parse(rawFile.originFileObj, {
            complete: (results) => {
              console.log("papa parse headers result:", results.data[0]);
              var parsedCSVHeaders = results.data[0];
              setCsvHeadersToSelect(parsedCSVHeaders);
            },
          });
        });
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
      // Parse local CSV file
      // Papa.parse(e.dataTransfer.files[0], {
      //   complete: function (results) {
      //     console.log("papa parse result:", results.data);
      //   },
      // });
    },
  };

  /**
   * handle save company from csv logic, parses csv and saves companies
   */
  const handleParseCSV = async (
    uploadedCSVFileName,
    emailColumnHeader,
    linkedInUrlColumnHeader,
    firstNameHeaderList,
    lastNameHeaderList,
    companyNameHeaderList
  ) => {
    console.log(emailColumnHeader, linkedInUrlColumnHeader);

    //parsing logic
    rawCSVFileObjectList.forEach((rawFile) => {
      Papa.parse(rawFile.originFileObj, {
        complete: (results) => {
          console.log("papa parse result:", results.data);
          var parsedCSVData = results.data;
          var parsedCSVHeaders = results.data[0];

          var combinedCSVData = [];

          //get index of headers from parsed csv data array
          const emailHeaderIndex = parsedCSVHeaders.indexOf(emailColumnHeader);
          const linkedInUrlHeaderIndex = parsedCSVHeaders.indexOf(
            linkedInUrlColumnHeader
          );
          const firstNameHeaderIndex = parsedCSVHeaders.indexOf(firstNameHeaderList);
          const lastNameHeaderIndex = parsedCSVHeaders.indexOf(lastNameHeaderList);
          const companyNameHeaderIndex = parsedCSVHeaders.indexOf(companyNameHeaderList);

          let isTotalEmpty = true;

          parsedCSVData.forEach((csvRow, index) => {
            /**ignore the first row as its header & check if
             * row is not undefined/null etc(happens due to
             * malformation of csv)
             */
            if (
              index !== 0 &&
              (isValid(csvRow[emailHeaderIndex]) === true ||
                isValid(csvRow[linkedInUrlHeaderIndex]) === true)
            ) {
              isTotalEmpty = false;

              // individual row of csv as single object
              // check if this column exists in csv by checking index !== -1 else add a empty string
              let payload = {
                email: emailHeaderIndex !== -1 ? csvRow[emailHeaderIndex] : "",
                linkedInUrl:
                  linkedInUrlHeaderIndex !== -1
                    ? removeParamsFromUrl(csvRow[linkedInUrlHeaderIndex])
                    : "",
                firstName: firstNameHeaderIndex !== -1 ? csvRow[firstNameHeaderIndex] : "",
                lastName: lastNameHeaderIndex !== -1 ? csvRow[lastNameHeaderIndex] : "",
                companyName: companyNameHeaderIndex !== -1 ? csvRow[companyNameHeaderIndex] : "",
              };
              combinedCSVData.push(payload);
            }
          });

          if (isTotalEmpty === true) {
            message.error(
              "Selected column is empty, please select a different column"
            );
            setConfirmLoading(false);
            return;
          }

          // use these data to save
          handleSaveCSVForEnrichment(combinedCSVData, uploadedCSVFileName);
        },
      });
    });
  };

  const handleSaveCSVForEnrichment = async (
    combinedCSVData,
    uploadedCSVFileName,
  ) => {
    // set status for csv file
    const { personalisationCredits } = await getCredits(UserId);
    let fileStatus = "Enriching...";

    if (+personalisationCredits <= 0) {
      fileStatus = "Out of Credits";
    }

    await saveCSVFileForEnrichment(
      UserId,
      uploadedCSVFileName,
      combinedCSVData,
      fileStatus,
      icebreakerToggleStatus
    );

    setConfirmLoading(false);
    setRawCSVFileObjectList([]);
    setCsvHeadersToSelect([]);
    setDataUploadModalVisible(false);
    getSavedCSVList();
  };

  return loggedInUser ? (
    <div className={styles.Enrich}>
      {/** Upload CSV list modal */}

      <Modal
        title=""
        bodyStyle={{ minHeight: "500px" }}
        visible={dataUploadModalVisible}
        onOk={() => {
          setDataUploadModalVisible(false);
        }}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setDataUploadModalVisible(false);
          uploadCSVForEnrichmentForm.resetFields();
          setIcebreakerToggleStatus(false);
        }}
        footer={null}
      >
        <Form
          form={uploadCSVForEnrichmentForm}
          name="basic"
          layout="vertical"
          style={{
            margin: "30px 0px 10px 0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          labelCol={{
            span: 30,
          }}
          wrapperCol={{
            span: 100,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={async (values) => {
            // get values from form input/selectors
            let uploadedCSVFileName = values["csv-file-name"];
            let emailColumnHeaderList = values["email-header-name"];
            let linkedInUrlColumnHeaderList = values["linkedinUrl-header-name"];
            let firstNameHeaderList = values["first-name-header"];
            let lastNameHeaderList = values["last-name-header"];
            let companyNameHeaderList = values["company-name-header"];

            //proceed only if email or linkedin url column name is provided
            if (
              isValid(emailColumnHeaderList) ||
              isValid(linkedInUrlColumnHeaderList)
            ) {
              setConfirmLoading(true);

              //loop that parses csv in saves all companies by calling saveCompany api
              await handleParseCSV(
                uploadedCSVFileName,
                emailColumnHeaderList,
                linkedInUrlColumnHeaderList,
                firstNameHeaderList,
                lastNameHeaderList,
                companyNameHeaderList
              );

              uploadCSVForEnrichmentForm.resetFields();
              setIcebreakerToggleStatus(false);
            } else {
              message.error(
                "Please select Email or/and LinkedIn URL column name"
              );
            }
          }}
          onFinishFailed={(errorInfo) => {
            console.log("Failed:", errorInfo);
            setIcebreakerToggleStatus(false);
          }}
          autoComplete="off"
        >
          <Form.Item
            name="dragger"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Select a CSV file",
              },
            ]}
          >
            <Upload.Dragger
              name="files"
              {...fileUploaderProps}
              style={{ margin: "0px 0px 0px 0px", padding: "10px" }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single CSV file. File should contain email or
                linkedIn profile URL of leads or both
              </p>
            </Upload.Dragger>
          </Form.Item>

          <div className={styles.toggleContainer}>
            <p className={styles.toggleContainerText}>Generate Icebreakers</p>
            <Switch checked={icebreakerToggleStatus} onChange={() => setIcebreakerToggleStatus(!icebreakerToggleStatus)} />
          </div>

          <Form.Item
            style={{ width: "90%", paddingLeft: "10%" }}
            name="csv-file-name"
            label="CSV file name"
            tooltip="Leadzilla will send you job posting alerts for these keywords in job description"
            rules={[
              {
                required: true,
                message: "File name is required",
              },
            ]}
          >
            <Input placeholder="eg: Leads from EU" />
          </Form.Item>

          <Form.Item
            style={{ width: "90%", paddingLeft: "10%" }}
            name="email-header-name"
            label="Email column"
            tooltip="Name of column in csv that contains emails"
            rules={[
              {
                required: false,
                message: "Please enter multiple job keywords!",
              },
            ]}
          >
            <Select placeholder="eg: Email" allowClear>
              {csvHeadersToSelect.map((headers) => {
                return <Option key={headers}>{headers}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ width: "90%", paddingLeft: "10%" }}
            name="linkedinUrl-header-name"
            label="LinkedIn URL column"
            tooltip="Name of column in csv that contains LinkedIn URL"
            rules={[
              {
                required: false,
                message: "Please enter multiple title keywords!",
              },
            ]}
          >
            <Select placeholder="eg: LinkedIn URL" allowClear>
              {csvHeadersToSelect.map((headers) => {
                return <Option key={headers}>{headers}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ width: "90%", paddingLeft: "10%" }}
            name="first-name-header"
            label="First Name column"
            tooltip="Name of column in csv that contains First Name"
            rules={[
              {
                required: false,
                message: "Please enter First Name",
              },
            ]}
          >
            <Select placeholder="eg: First Name" allowClear>
              {csvHeadersToSelect.map((headers) => {
                return <Option key={headers}>{headers}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ width: "90%", paddingLeft: "10%" }}
            name="last-name-header"
            label="Last Name column"
            tooltip="Name of column in csv that contains Last Name"
            rules={[
              {
                required: false,
                message: "Please enter Last Name",
              },
            ]}
          >
            <Select placeholder="eg: Last Name" allowClear>
              {csvHeadersToSelect.map((headers) => {
                return <Option key={headers}>{headers}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ width: "90%", paddingLeft: "10%" }}
            name="company-name-header"
            label="Company Name column"
            tooltip="Name of column in csv that contains Company Name"
            rules={[
              {
                required: false,
                message: "Please enter Company Name",
              },
            ]}
          >
            <Select placeholder="eg: Company Name" allowClear>
              {csvHeadersToSelect.map((headers) => {
                return <Option key={headers}>{headers}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 7,
              span: 20,
            }}
          >
            <Button type="primary" htmlType="submit" loading={confirmLoading}>
              Save CSV for enrichment
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Topnav />

      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.headerText}>
            <h1>Enrich your lead list</h1>
            <h3>
              Enrich your CSV file with Leadzilla's contact & personalization
              data.
            </h3>
          </span>
          <button
            className="primary-button"
            style={{ margin: "0px 0px 0px auto" }}
            onClick={() => {
              //viewAllSelectedContacts(selectedUserData);
              setDataUploadModalVisible(true);
            }}
          >
            Upload CSV file <PlusOutlined />
          </button>
        </div>

        <div className={styles.enrichTableCard}>
          <Table
            headers={false}
            style={{ padding: "0px 30px" }}
            size="large"
            columns={enrichmentListTableColumn}
            loading={{
              indicator: (
                <LoadingOutlined
                  style={{
                    fontSize: 40,
                    fontWeight: 600,
                  }}
                />
              ),
              spinning: loading,
            }}
            rowKey="index"
            // rowSelection={{ ...rowSelection }}
            dataSource={[...savedCSVFileList]}
            // pagination={{ pageSize: 100 }}
            pagination={true}
            scroll={{ y: "max-content" }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 60,
              fontWeight: 600,
            }}
            spin
          />
        }
      />
    </div>
  );
}
