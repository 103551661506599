/**
Stores latest people search filter values
 */

import React, { useState, createContext } from "react";

export const CompanySearchQueryContext = createContext();

export const CompanySearchQueryContextProvider = (props) => {
  const [CompanySearchQuery, setCompanySearchQuery] = useState({
    companyName: [],
    companyDomain: [],
    industry: [],
    headcount: [],
    revenue: [],
    country: [],
    similarTo: [],
    technology: [],
  });

  return (
    <CompanySearchQueryContext.Provider
      value={[CompanySearchQuery, setCompanySearchQuery]}
    >
      {props.children}
    </CompanySearchQueryContext.Provider>
  );
};
