import Papa from "papaparse";
import { saveAs as saveFile } from "file-saver";

// takes a csv object-array & filename string and saves csv file on desktop
const downloadCSV = (csvArray, fileName) => {
    var csv = Papa.unparse(csvArray);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveFile(csvData, `${fileName}`);
}

const waitFor = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export { downloadCSV, waitFor };

