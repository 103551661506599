import React, { useEffect, useState, useContext } from "react";
import styles from "./MyContacts.module.css";
import Topnav from "../../Components/Topnav/Topnav";
import {
    LoadingOutlined,
} from "@ant-design/icons";
import { onAuthStateChanged } from "firebase/auth";
import {
    db,
    auth,
    getContacts,
} from "../../firebase-config";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { isValid, removeParamsFromUrl } from "../../Utils/list.js";
import { useNavigate } from "react-router-dom";
import {
    Spin,
    Table,
    Typography
} from "antd";
import { UserIdContext } from "../../Context/UserIdContext";
import { CreditCountContext } from "../../Context/CreditCountContext";
import { downloadCSV } from "../../Utils/utils";

const { Paragraph } = Typography;

var accessToken = "";
var currentCredit = 0;

export default function MyContacts(props) {
    //misc states
    const [loggedInUser, setLoggedInUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firebaseAuthUUID, setFirebaseAuthUUID] = useState("");
    const [UserId, setUserId] = useContext(UserIdContext);
    const { CreditCount, setCreditCount } = useContext(CreditCountContext);
    const { AICreditCount, setAICreditCount } = useContext(CreditCountContext);
    const [contactList, setContactList] = useState([]);
    let navigate = useNavigate();

    onAuthStateChanged(auth, async (user) => {
        if (user) {
            //console.log("user logged in");

            //fetch and saved firebase auth access token
            await user.getIdToken().then((token) => {
                accessToken = token;
            });

            setLoggedInUser(user);
            setFirebaseAuthUUID(user.uid);
            setUserId(user.uid);

            onSnapshot(doc(db, "users", `${user.uid}`), (doc) => {
                currentCredit = doc.data().credits;
                setCreditCount(currentCredit);
            });
        } else {
            console.log("user not logged in");
            navigate("/login");
        }
    });

    const getSavedContacts = async () => {
        setLoading(true);

        if (isValid(UserId)) {
            let list = await getContacts(UserId)

            if (list === undefined) {
                setLoading(false);
                return;
            }

            let newList = list.map((item) => {
                return {
                    email: item.emailAddress,
                    name: `${item.firstName} ${item.lastName}`,
                    company: item.companyName,
                    numbers: [
                        {
                            "key": "Company",
                            "value": item.phoneCompany || ""
                        },
                        {
                            "key": "Mobile",
                            "value": item.phoneMobile || ""
                        },
                        {
                            "key": "Number",
                            "value": item.phoneNumbers[0] || null,
                        }
                    ],
                };
            });
            newList.reverse();

            setContactList([...newList]);
            setLoading(false);
        } else {
            console.log("Waiting for firebase auth to load");
            setLoading(false);
        }
    }

    const handleCSVDownload = async () => {
        setLoading(true);
        console.log("Downloading CSV");

        if (isValid(UserId)) {
            let list = await getContacts(UserId)

            if (list === undefined) {
                setLoading(false);
                return;
            }

            let newList = list.map((item) => {
                return {
                    firstName: isValid(item.firstName) ? item.firstName : "",
                    lastName: isValid(item.lastName) ? item.lastName : "",
                    fullName: isValid(item.firstName) && isValid(item.lastName) ? `${item.firstName} ${item.lastName}` : "",
                    emailAddress: isValid(item.emailAddress) ? item.emailAddress : "",
                    company: isValid(item.companyName) ? item.companyName : "",
                    linkedInProfileUrl: isValid(item.linkedInProfileUrl) ? item.linkedInProfileUrl : "",
                    phoneCompany: isValid(item.phoneCompany) ? item.phoneCompany : "",
                    phoneMobile: isValid(item.phoneMobile) ? item.phoneMobile : "",
                    phoneNumbers: isValid(item.phoneNumbers) ? item.phoneNumbers : [],
                };
            });

            downloadCSV(newList, "my-contacts")
            setLoading(false);
        } else {
            console.log("Waiting for firebase auth to load");
            setLoading(false);
        }
    }

    useEffect(() => {
        getSavedContacts();
    }, [CreditCount]);

    const tableColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (record, index) => (
                <div
                    style={{ display: "flex", flexDirection: "column", maxWidth: "90%" }}
                >
                    <h4>{record}</h4>
                </div>
            ),
        },

        {
            title: "Company",
            dataIndex: "company",
            key: "company",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (record, index) => (
                <div
                    style={{ display: "flex", flexDirection: "column", maxWidth: "90%" }}
                >
                    <h4>{record}</h4>
                </div>
            ),
        },

        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (record, index) => (
                <div
                    className={styles.data_wrapper}
                >
                    {
                        isValid(record) ?
                            <>
                                <h4>{isValid(record) ? record : ""}</h4>
                                <Paragraph
                                    className={styles.copyable_text}
                                    copyable={{ text: `${record}` }}>
                                </Paragraph>
                            </> : ""
                    }
                </div>
            ),
        },

        {
            title: "Numbers",
            dataIndex: "numbers",
            key: "numbers",
            render: (record, index) => (
                <div
                    style={{ display: "flex", flexDirection: "column", maxWidth: "90%" }}
                >
                    {
                        record.map((el, index) => {
                            if (el.value !== "" && el.value !== undefined && el.value !== null && el.value !== []) {
                                return (
                                    <div className={styles.data_wrapper}>
                                        <h4 className={styles.number_key}>{el.key}</h4>
                                        <h4 className={styles.number_value}>{el.value}</h4>
                                        <Paragraph
                                            className={styles.copyable_text}
                                            copyable={{ text: `${el.value}` }}>
                                        </Paragraph>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            ),
        },
    ];



    return loggedInUser ? (
        <div className={styles.wrapper}>
            <Topnav />

            <div className={styles.wrapper_content}>
                <div className={styles.button_container}>
                    <button
                        className="primary-button"
                        onClick={() => handleCSVDownload()}
                    >
                        Download
                    </button>
                </div>
                <div className={styles.contact_table}>
                    <Table
                        headers={false}
                        style={{ padding: "0px 30px" }}
                        size="large"
                        columns={tableColumns}
                        loading={{
                            indicator: (
                                <LoadingOutlined
                                    style={{
                                        fontSize: 40,
                                        fontWeight: 600,
                                    }}
                                />
                            ),
                            spinning: loading,
                        }}
                        rowKey="index"
                        dataSource={[...contactList]}
                        pagination={true}
                        scroll={{ y: "max-content" }}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div
            style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin
                indicator={
                    <LoadingOutlined
                        style={{
                            fontSize: 60,
                            fontWeight: 600,
                        }}
                        spin
                    />
                }
            />
        </div>
    );
}
