import React, { useState, useContext, useEffect } from "react";
import "./Onboarding.css";
import Topnav from "../../Components/Topnav/Topnav";
import { onAuthStateChanged } from "firebase/auth";
import {
  auth,
  updateLeadzillaUserProductName,
  updateLeadzillaUserProductDescription,
  saveLeadzillaEmailBody,
  generateEmailBody,
} from "../../firebase-config";
import { signOut } from "firebase/auth";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase-config";
import { useNavigate } from "react-router-dom";
import { Spin, Button, Steps, message, Cascader, Select, Input } from "antd";
import loginPageCreative from "../../Assets/login-page-creatives.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { UserIdContext } from "../../Context/UserIdContext";
import LeadzillaLogo from "../../Assets/leadzilla-full-logo.svg";
import { PeopleSearchQueryContext } from "../../Context/PeopleSearchQueryContext";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import {
  industryCascaderOptions,
  country_list,
  isValid,
} from "../../Utils/list.js";

var accessToken = "";

// const serverURL = "http://localhost:6060";
// const serverURL = "https://leadzilla.herokuapp.com";
const serverURL = process.env.REACT_APP_SERVER_URL;

const { Step } = Steps;
const { Option } = Select;
const { TextArea } = Input;

const steps = [
  {
    title: "Prospect",
    content: "",
  },
  {
    title: "Product",
    content: "",
  },
];

function Onboarding() {
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [firebaseAuthUUID, setFirebaseAuthUUID] = useState("");
  const [onboardingComplete, setOnboardingComplete] = useState(null);

  const [UserId, setUserId] = useContext(UserIdContext);

  const [currentStep, setCurrentStep] = useState(0);

  // filter selectors states
  const [PeopleSearchQuery, setPeopleSearchQuery] = useContext(
    PeopleSearchQueryContext
  );
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedCompanySize, setSelectedCompanySize] = useState([]);
  const [selectedCompanyRevenue, setSelectedCompanyRevenue] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);

  const [leadzillaUserCompanyName, setLeadzillaUserCompanyName] = useState("");
  const [leadzillaUserProductDescription, setLeadzillaUserProductDescription] =
    useState("");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      //console.log("user logged in");

      //fetch and saved firebase auth access token
      await user.getIdToken().then((token) => {
        // setAccessToken(token);
        accessToken = token;
        //console.log(token);
      });

      setLoggedInUser(user);
      setFirebaseAuthUUID(user.uid);
      setUserId(user.uid);

      onSnapshot(doc(db, "users", `${user.uid}`), (doc) => {
        //console.log("Current data: ", doc.data());
        setOnboardingComplete(doc.data().onboardingComplete);
        //console.log(doc.data().onboardingComplete);
      });
    } else {
      console.log("user not logged in");
      navigate("/login");
    }
  });

  useEffect(() => {
    if (loggedInUser && onboardingComplete === true) {
      navigate("/search/people");
    }
  }, [loggedInUser, onboardingComplete]);

  //check if loggedin email is company email, if yes generated companyName & productDescription & auto-fill inputs
  useEffect(() => {
    console.log("autofill");
    if (isValid(loggedInUser?.email)) {
      autoFillCompanyData(loggedInUser.email);
    }
  }, [loggedInUser]);

  const autoFillCompanyData = async (email) => {
    let emailTypeData = await isPersonalEmail(email);
    let emailIdIsPersonal = emailTypeData.isPersonalEmail;
    console.log(emailIdIsPersonal);

    if (emailIdIsPersonal === false) {
      let productData = await getProductDescriptionFromCompanyEmail(email);
      console.log("fetching product data....", productData);
      setLeadzillaUserCompanyName(productData.productName);
      setLeadzillaUserProductDescription(productData.productDescription);
    }
  };

  const LogOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };

  const updateUserOnboardingStatus = async (emailBody) => {
    // prepare user's default Product Data
    let productId = uuidv4();
    let defaultProduct = {
      productId: productId,
      default: true,
      product: leadzillaUserCompanyName,
      productDescription: leadzillaUserProductDescription,
      emailBody: emailBody
    }

    const userDataRef = doc(db, "users", `${UserId}`);
    updateDoc(userDataRef, {
      onboardingComplete: true,
      productList: [defaultProduct]
    });
  };

  const saveProductName = () => {
    updateLeadzillaUserProductName(UserId, leadzillaUserCompanyName);
  };

  const saveProductDescription = () => {
    updateLeadzillaUserProductDescription(
      UserId,
      leadzillaUserProductDescription
    );
  };

  const saveProductEmailBody = (emailBody) => {
    saveLeadzillaEmailBody(UserId, emailBody);
  };

  /** generates email body using companyName & companyDescription */
  const fetchPersonalizeEmail = async (companyName, companyDescription) => {
    let emailBody = await generateEmailBody(companyName, companyDescription, UserId)
    return emailBody;
  };

  /**Check if user's email is company email or not */
  const isPersonalEmail = async (email) => {
    let productData = {};
    try {
      await axios
        .post(
          `${serverURL}/isPersonalEmail`,
          JSON.stringify({ email: `${email}` }),
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              //Autherization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          productData = response?.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return productData;
    } catch (err) {
      console.log(err);
    }
  };

  /** generates product name & description from company email */
  const getProductDescriptionFromCompanyEmail = async (companyEmail) => {
    let isPersonalEmail = null;
    try {
      await axios
        .post(
          `${serverURL}/findProductNameAndDescriptionfromWorkEmailOnSignup`,
          JSON.stringify({ email: `${companyEmail}` }),
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              //Autherization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          isPersonalEmail = response?.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return isPersonalEmail;
    } catch (err) {
      console.log(err);
    }
  };

  return loggedInUser ? (
    <div className="Onboarding">
      <div className="content">
        <div className="onboarding-side">
          <img
            className="leadzilla-logo"
            src={LeadzillaLogo}
            style={{ height: "60px", margin: "0 0 20px 0" }}
            alt="leadzilla-logo"
          />
          <div className="text-container">
            <h1>Lets take 1 minute to customize your experience</h1>
          </div>

          <div className="onboarding-container">
            <OnboardingSteps
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              selectedIndustry={selectedIndustry}
              setSelectedIndustry={setSelectedIndustry}
              selectedCompanySize={selectedCompanySize}
              setSelectedCompanySize={setSelectedCompanySize}
              selectedCompanyRevenue={selectedCompanyRevenue}
              setSelectedCompanyRevenue={setSelectedCompanyRevenue}
              selectedLevel={selectedLevel}
              setSelectedLevel={setSelectedLevel}
              selectedDepartment={selectedDepartment}
              setSelectedDepartment={setSelectedDepartment}
              leadzillaUserCompanyName={leadzillaUserCompanyName}
              setLeadzillaUserCompanyName={setLeadzillaUserCompanyName}
              leadzillaUserProductDescription={leadzillaUserProductDescription}
              setLeadzillaUserProductDescription={
                setLeadzillaUserProductDescription
              }
              PeopleSearchQuery={PeopleSearchQuery}
              setPeopleSearchQuery={setPeopleSearchQuery}
              updateUserOnboardingStatus={updateUserOnboardingStatus}
              saveProductName={saveProductName}
              saveProductDescription={saveProductDescription}
              saveProductEmailBody={saveProductEmailBody}
              fetchPersonalizeEmail={fetchPersonalizeEmail}
              loading={loading}
              setLoading={setLoading}
            />
            <Button style={{ marginTop: "60px" }} danger onClick={LogOut}>
              Login with another account
            </Button>
          </div>
        </div>
        <div className="banner-side">
          <img src={loginPageCreative} alt="" />
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 60,
              fontWeight: 600,
            }}
            spin
          />
        }
      />
    </div>
  );
}

function OnboardingSteps(props) {
  const navigate = useNavigate();

  const nextStep = () => {
    props.setCurrentStep(props.currentStep + 1);
  };

  const prevStep = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  // handle changes on filter selectors
  function handleIndustryChange(value) {
    props.setSelectedIndustry(value);
    //update filter value in search query in context
    let searchQuery = props.PeopleSearchQuery;
    searchQuery["industry"] = value;
    props.setPeopleSearchQuery(searchQuery);
  }
  function handleCompanySizeChange(value) {
    props.setSelectedCompanySize(value);
    //update filter value in search query in context
    let searchQuery = props.PeopleSearchQuery;
    searchQuery["headcount"] = value;
    props.setPeopleSearchQuery(searchQuery);
  }

  function handleCompanyRevenueChange(value) {
    props.setSelectedCompanyRevenue(value);
    //update filter value in search query in context
    let searchQuery = props.PeopleSearchQuery;
    searchQuery["revenue"] = value;
    props.setPeopleSearchQuery(searchQuery);
  }

  function handleLevelChange(value) {
    props.setSelectedLevel(value);
    //update filter value in search query in context
    let searchQuery = props.PeopleSearchQuery;
    searchQuery["seniority"] = value;
    props.setPeopleSearchQuery(searchQuery);
  }

  function handleDepartmentChange(value) {
    props.setSelectedDepartment(value);
    //update filter value in search query in context
    let searchQuery = props.PeopleSearchQuery;
    searchQuery["department"] = value;
    props.setPeopleSearchQuery(searchQuery);
  }

  const companySizeOptions = [
    <Option key={"0 - 25"}>{"0 - 25"}</Option>,
    <Option key={"25 - 100"}>{"25 - 100"}</Option>,
    <Option key={"100 - 250"}>{"100 - 250"}</Option>,
    <Option key={"250 - 1000"}>{"250 - 1000"}</Option>,
    <Option key={"1k - 10k"}>{"1k - 10k"}</Option>,
    <Option key={"10k - 50k"}>{"10k - 50k"}</Option>,
    <Option key={"50k - 100k"}>{"50k - 100k"}</Option>,
    <Option key={"> 100k"}>{"> 100k"}</Option>,
  ];

  const companyRevenueOptions = [
    <Option key={"$0 - 1M"}>{"$0 - 1M"}</Option>,
    <Option key={"$1 - 10M"}>{"$1 - 10M"}</Option>,
    <Option key={"$10 - 50M"}>{"$10 - 50M"}</Option>,
    <Option key={"$50 - 100M"}>{"$50 - 100M"}</Option>,
    <Option key={"$100 - 250M"}>{"$100 - 250M"}</Option>,
    <Option key={"$250 - 500M"}>{"$250 - 500M"}</Option>,
    <Option key={"$500M - 1B"}>{"$500M - 1B"}</Option>,
    <Option key={"> $1B"}>{"> $1B"}</Option>,
  ];

  const levelOptions = [
    <Option key={"C-Level"}>{"C-Level"}</Option>,
    <Option key={"VP-Level"}>{"VP-Level"}</Option>,
    <Option key={"Director-Level"}>{"Director-Level"}</Option>,
    <Option key={"Manager-Level"}>{"Manager-Level"}</Option>,
    <Option key={"Staff"}>{"Staff"}</Option>,
    <Option key={"Other"}>{"Other"}</Option>,
  ];

  const departmentOptions = [
    <Option key={"Engineering"}>{"Engineering"}</Option>,
    <Option key={"Finance & Administration"}>
      {"Finance & Administration"}
    </Option>,
    <Option key={"Human Resources"}>{"Human Resources"}</Option>,
    <Option key={"IT & IS"}>{"IT & IS"}</Option>,
    <Option key={"Marketing"}>{"Marketing"}</Option>,
    <Option key={"Operations"}>{"Operations"}</Option>,
    <Option key={"Sales"}>{"Sales"}</Option>,
    <Option key={"Support"}>{"Support"}</Option>,
    <Option key={"Other"}>{"Other"}</Option>,
  ];

  return (
    <>
      <Steps current={props.currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">
        {props.currentStep === 0 ? (
          <>
            <p className="step-description">
              This will be used to find ideal prospects for you
            </p>

            <Cascader
              bordered={true}
              multiple
              optionLabelProp="label"
              allowClear
              placeholder="Select industry"
              style={{
                width: "300px",
                margin: "5px 0px 10px 0px",
              }}
              options={industryCascaderOptions}
              onChange={handleIndustryChange}
              value={props.selectedIndustry}
              showSearch={(inputValue, path) =>
                path.some(
                  (option) =>
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) > -1
                )
              }
            />

            <Select
              bordered={true}
              mode="multiple"
              optionLabelProp="label"
              allowClear
              placeholder="Select employee headcount"
              onChange={handleCompanySizeChange}
              value={props.selectedCompanySize}
              style={{ width: "300px", margin: "5px 0px 10px 0px" }}
            >
              {companySizeOptions}
            </Select>

            {/* <Select
              bordered={true}
              mode="multiple"
              optionLabelProp="label"
              allowClear
              placeholder="Select company revenue"
              onChange={handleCompanyRevenueChange}
              value={props.selectedCompanyRevenue}
              style={{ width: "300px", margin: "5px 0px 10px 0px" }}
            >
              {companyRevenueOptions}
            </Select> */}

            <Select
              bordered={true}
              mode="multiple"
              optionLabelProp="label"
              allowClear
              placeholder="Select seniority"
              onChange={handleLevelChange}
              value={props.selectedLevel}
              style={{ width: "300px", margin: "5px 0px 10px 0px" }}
            >
              {levelOptions}
            </Select>

            <Select
              bordered={true}
              mode="multiple"
              optionLabelProp="label"
              allowClear
              placeholder="Select department"
              onChange={handleDepartmentChange}
              value={props.selectedDepartment}
              style={{ width: "300px", margin: "5px 0px 10px 0px" }}
            >
              {departmentOptions}
            </Select>
          </>
        ) : (
          <>
            <p className="step-description">
              This will be used to generate personalized emails & icebreakers
              for your client
            </p>
            <Input
              placeholder="Your company name"
              style={{ width: "300px", margin: "5px 0px 10px 0px" }}
              bordered={true}
              allowClear
              value={props.leadzillaUserCompanyName}
              onChange={(e) => {
                console.log(e.target.value);
                props.setLeadzillaUserCompanyName(e.target.value);
              }}
            />

            <TextArea
              showCount={false}
              maxLength={400}
              placeholder="Your product description (min 100 words)"
              style={{
                height: 120,
                width: "300px",
                margin: "5px 0px 10px 0px",
              }}
              value={props.leadzillaUserProductDescription}
              onChange={(e) => {
                console.log(e.target.value);
                props.setLeadzillaUserProductDescription(e.target.value);
              }}
            />
          </>
        )}
      </div>
      <div className="steps-action">
        {props.currentStep < steps.length - 1 && (
          <Button type="primary" onClick={() => nextStep()}>
            Next
          </Button>
        )}
        {props.currentStep === steps.length - 1 && (
          <Button
            loading={props.loading}
            type="primary"
            onClick={async () => {
              if (
                props.selectedIndustry.length > 0 &&
                props.selectedCompanySize.length > 0 &&
                props.selectedLevel.length > 0 &&
                props.leadzillaUserCompanyName.length > 0 &&
                props.leadzillaUserProductDescription.length > 0
              ) {
                if (props.leadzillaUserProductDescription.length >= 15) {
                  await props.saveProductName();
                  await props.saveProductDescription();

                  props.setLoading(true);

                  let emailBody = await props.fetchPersonalizeEmail(
                    props.leadzillaUserCompanyName,
                    props.leadzillaUserProductDescription
                  );

                  await props.saveProductEmailBody(emailBody);
                  await props.updateUserOnboardingStatus(emailBody);
                  props.setLoading(false);

                  navigate("/search/people");
                  message.success("Welcome to Leadzilla!");
                } else {
                  console.log(props.leadzillaUserProductDescription.length);
                  message.error(
                    "Description should be at least 15 characters long"
                  );
                }
              } else {
                message.error("Please select all option!");
              }
            }}
          >
            Done
          </Button>
        )}
        {props.currentStep > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prevStep()}
          >
            Previous
          </Button>
        )}
      </div>
    </>
  );
}

export default Onboarding;
