import React, { useEffect, useState } from 'react';
import styles from './CustomPagination.module.css';

function CustomPagination({ totalPages, activePage, prevFunc, nextFunc, moveToPage }) {
    const [currentPage, setCurrentPage] = useState(activePage + 1);

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            prevFunc();
        }
    };

    const handleNextClick = () => {
        if (currentPage <= totalPages) {
            setCurrentPage(currentPage + 1);
            nextFunc();
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        moveToPage(pageNumber - 1);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 10; // Maximum number of page buttons to show in between prev and next buttons
        const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 2);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    useEffect(() => {
        setCurrentPage(activePage + 1);
    }, [activePage]);

    const renderPageNumbers = () => {
        const pageNumbers = getPageNumbers();

        return (
            <>
                {currentPage > 1 ?
                    <button style={{fontWeight: 600}} className={styles.pageButtons} onClick={handlePrevClick}>Prev</button>
                    :
                    <button className={styles.pageButtonDisabled} disabled>Prev</button>
                }

                {pageNumbers.map((pageNumber) => (
                    <button
                        key={pageNumber}
                        onClick={() => handlePageClick(pageNumber)}
                        className={currentPage === pageNumber ? styles.pageButtonActive : styles.pageButtons}
                    >
                        {pageNumber}
                    </button>
                ))}

                {currentPage <= totalPages ?
                    <button style={{fontWeight: 600}} className={styles.pageButtons} onClick={handleNextClick}>Next</button>
                    :
                    <button className={styles.pageButtonDisabled} disabled>Next</button>
                }
            </>
        );
    };

    return (
        <div className={styles.wrapper}>
            {renderPageNumbers()}
        </div>
    );
}

export default CustomPagination;
