import React from 'react';
import './Banner.css';

const Banner = () => {
    return (
        <div className='wrapper'>
            Leadzilla is no longer active. It is being merged with 
            <a 
            href='https://salesrobot.co/' 
            target='_blank' 
            rel="noreferrer" 
            className='link_text'>Salesrobot</a>. The site will stop functioning on 1st Dec 2023
        </div>
    )
}

export default Banner;