import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import { UserIdContextProvider } from "./Context/UserIdContext";
import { CreditCountContextProvider } from "./Context/CreditCountContext";
import { PeopleSearchQueryContextProvider } from "./Context/PeopleSearchQueryContext";
import { CompanySearchQueryContextProvider } from "./Context/CompanySearchQueryContext";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserIdContextProvider>
        <CreditCountContextProvider>
          <PeopleSearchQueryContextProvider>
            <CompanySearchQueryContextProvider>
              <App />
            </CompanySearchQueryContextProvider>
          </PeopleSearchQueryContextProvider>
        </CreditCountContextProvider>
      </UserIdContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
