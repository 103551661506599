// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  addDoc,
  getDocs,
  where,
  query,
  collection,
  deleteDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import moment from "moment";
import axios from "axios";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAR933WHOV8rPS0LC-cOaCpfP4NYFMuTZc",
  authDomain: "leadzilla-4c8c9.firebaseapp.com",
  projectId: "leadzilla-4c8c9",
  storageBucket: "leadzilla-4c8c9.appspot.com",
  messagingSenderId: "149985000313",
  appId: "1:149985000313:web:ae31fbdd0562630ed74da8",
  measurementId: "G-M041H29TN4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
const serverURL = process.env.REACT_APP_SERVER_URL;
const provider = new GoogleAuthProvider();

//SignIn with google
export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;
      console.log(user);
      createUserInFirestore(user.uid, user.email);
    })
    .catch((error) => {
      // Handle Errors here.
      console.log(error);
    });
};

//save user data
export const createUserInFirestore = async (
  firebaseUserUUID,
  firebaseUserEmail
) => {
  try {
    console.log("user exists, getting user document");
    const docSnap = await getDoc(doc(db, "users", `${firebaseUserUUID}`));
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No user data!, creating one");
      // Add a new document in collection "Users"
      await setDoc(doc(db, "users", `${firebaseUserUUID}`), {
        credits: 20,
        personalisationCredits: 4,
        base_credits: 0,
        base_ai_credits: 0,
        account: "business",
        salesforce: {
          instance_url: "",
          refresh_token: "",
          enabled: false,
        },
        email: `${firebaseUserEmail}`,
        onboardingComplete: false,
        firebase_auth_uuid: `${firebaseUserUUID}`,
        subscription_id: "none",
      });
    }
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

export const getCreditsInfo = async (firebaseUserUUID) => {
  const docSnap = await getDoc(doc(db, "users", `${firebaseUserUUID}`));
  let credits = docSnap.credits;
  return credits;
};

/**
 * All firebase helper queries regarding company lists
 */

export const createCompanyList = async (
  firebaseUserUUID,
  listName,
  jobKeywordsList,
  titleKeywordsList
) => {
  try {
    // check if this list already exists in db
    const querySnapshot = await getDocs(
      query(
        collection(db, "company_list"),
        where("firebase_auth_uuid", "==", firebaseUserUUID),
        where("listName", "==", listName)
      )
    );
    console.log(querySnapshot.docs.length);
    if (querySnapshot.docs.length === 0) {
      //console.log(firebaseUserUUID, listName);
      //check if content are not empty
      if ((firebaseUserUUID.length > 0, listName.length > 0)) {
        // Save list
        const docRef = await addDoc(collection(db, "company_list"), {
          contentCount: 0,
          listName: `${listName}`,
          firebase_auth_uuid: `${firebaseUserUUID}`,
          jobKeywords: [...jobKeywordsList],
          titleKeywords: [...titleKeywordsList],
        });
        // console.log("Document written with ID: ", docRef.id);
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchCompanyList = async (firebaseUserUUID) => {
  let list = [];
  try {
    // check if this list already exists in db
    const querySnapshot = await getDocs(
      query(
        collection(db, "company_list"),
        where("firebase_auth_uuid", "==", firebaseUserUUID)
      )
    );
    console.log(querySnapshot.docs);
    querySnapshot.forEach((doc) => {
      list.push(doc.data());
      //console.log(doc.data());
      // console.log(doc.id, " => ", doc.data());
    });

    //console.log(list);
  } catch (err) {
    console.log(err);
  }
  return list;
};

/**
 * Deletes an entrie company list
 * first deletes all the saved companies inside that list one by one(inefficeint than bulk delete)
 * then deletes the company list itself
 */
export const deleteCompanyList = async (firebaseUserUUID, listName) => {
  try {
    //console.log(firebaseUserUUID, listName);
    //check if content are not empty
    if ((firebaseUserUUID.length > 0, listName.length > 0)) {
      //first delete all the saved companies inside that list

      //get all saved companies in this list
      let companies = await fetchSavedCompanies(firebaseUserUUID, listName);
      console.log(companies);

      // delete each company one by one
      companies.forEach(async (company) => {
        // console.log(
        //   firebaseUserUUID,
        //   company.listName,
        //   company.company,
        //   company.domain
        // );

        await deleteSavedCompany(
          firebaseUserUUID,
          company.listName,
          company.company,
          company.domain
        );
      });

      //now delete the actual list
      const listQuery = query(
        collection(db, "company_list"),
        where("firebase_auth_uuid", "==", firebaseUserUUID),
        where("listName", "==", listName)
      );
      const listQuerySnapshot = await getDocs(listQuery);
      listQuerySnapshot.forEach(async (record) => {
        //console.log(record.data());
        await deleteDoc(doc(db, "company_list", `${record.id}`));
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Deletes an entrie company list
 * first deletes all the saved companies inside that list in bulk(efficent)
 * then deletes the company list itself
 */
export const deleteCompanyListBulk = async (firebaseUserUUID, listName) => {
  try {
    const batch = writeBatch(db);

    console.log("deleting ->", listName);
    //console.log(firebaseUserUUID, listName);
    //check if content are not empty
    if ((firebaseUserUUID.length > 0, listName.length > 0)) {
      //first delete all the saved companies inside that list

      // check if this list already exists in db get their ids
      const querySnapshot = await getDocs(
        query(
          collection(db, "companies"),
          where("firebase_auth_uuid", "==", firebaseUserUUID),
          where("listName", "==", listName)
        )
      );

      // prepare batch delete with theri ids
      querySnapshot.forEach(async (record) => {
        //console.log(record.data());
        //console.log(record.id);
        let companyRef = doc(db, "companies", `${record.id}`);
        batch.delete(companyRef);
      });

      //commit all delete of companies in one go
      await batch.commit();

      //Now delete the actual list(eg: name description )
      const listQuery = query(
        collection(db, "company_list"),
        where("firebase_auth_uuid", "==", firebaseUserUUID),
        where("listName", "==", listName)
      );
      const listQuerySnapshot = await getDocs(listQuery);
      listQuerySnapshot.forEach(async (record) => {
        //console.log(record.data());
        await deleteDoc(doc(db, "company_list", `${record.id}`));
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Save a single company(domain) inside a given company list for tracking
 */

export const saveCompany = async (
  firebaseUserUUID,
  listName,
  domain,
  company
) => {
  try {
    console.log("saving this company to list");

    console.log(firebaseUserUUID, listName, domain, company);
    // check if this list already exists in db
    const querySnapshot = await getDocs(
      query(
        collection(db, "companies"),
        where("firebase_auth_uuid", "==", firebaseUserUUID),
        where("listName", "==", listName),
        where("company", "==", company),
        where("domain", "==", domain)
      )
    );
    if (querySnapshot.docs.length === 0) {
      //check if content are not empty
      if ((firebaseUserUUID.length > 0, listName.length > 0)) {
        // Save list

        const docRef = await addDoc(collection(db, "companies"), {
          listName: `${listName}`,
          domain: `${domain}` || "",
          firebase_auth_uuid: `${firebaseUserUUID}`,
          company: `${company}`,
          /** Set lastSyncTime to prev year so that backend-cron job fetches
           * alerts for the first time
           */
          lastSyncTime: moment().subtract(1, "years").toDate(),
          signals: {
            domain: `${domain}` || "",
            promotions: [],
            newhires: [],
            jobsposted: [],
            fundings: [],
            newsmentions: [],
          },
        });
        // console.log("Document written with ID: ", docRef.id);
      }
    }
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

/**
 * Saves multiple companies in a singal batch firestore
 */

export const saveCompanyInBulk = async (
  firebaseUserUUID,
  listName,
  companies
) => {
  try {
    const batch = writeBatch(db);
    companies.forEach(async (company) => {
      console.log("bulk: saving this company to list");

      console.log(firebaseUserUUID, listName, company.domain, company.company);

      //check if content are not empty
      if ((firebaseUserUUID.length > 0, listName.length > 0)) {
        // Save list

        const companyRef = doc(collection(db, "companies"));
        console.log("companyRef---->", companyRef);

        batch.set(companyRef, {
          listName: `${listName}`,
          domain: `${company.domain}` || "",
          firebase_auth_uuid: `${firebaseUserUUID}`,
          company: `${company.company}`,
          /** Set lastSyncTime to prev year so that backend-cron job fetches
           * alerts for the first time
           */
          lastSyncTime: moment().subtract(1, "years").toDate(),
          signals: {
            domain: `${company.domain}` || "",
            promotions: [],
            newhires: [],
            jobsposted: [],
            fundings: [],
            newsmentions: [],
          },
        });
        // console.log("Document written with ID: ", docRef.id);
      }
    });
    await batch.commit();
  } catch (err) {
    console.log(err);
  }
};

export const fetchSavedCompanies = async (
  firebaseUserUUID,
  companyListName
) => {
  let list = [];

  try {
    // check if this list already exists in db
    const querySnapshot = await getDocs(
      query(
        collection(db, "companies"),
        where("firebase_auth_uuid", "==", firebaseUserUUID),
        where("listName", "==", companyListName)
      )
    );
    console.log(querySnapshot.docs);
    querySnapshot.forEach((doc) => {
      list.push(doc.data());
      //console.log(doc.data());
      // console.log(doc.id, " => ", doc.data());
    });

    //console.log(list);
  } catch (err) {
    console.log(err);
  }
  return list;
};

export const deleteSavedCompany = async (
  firebaseUserUUID,
  listName,
  company,
  domain
) => {
  try {
    console.log(firebaseUserUUID, listName, domain, company);

    const q = query(
      collection(db, "companies"),
      where("firebase_auth_uuid", "==", firebaseUserUUID),
      where("listName", "==", listName),
      where("company", "==", company),
      where("domain", "==", domain)
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (record) => {
      //console.log(record.data());
      await deleteDoc(doc(db, "companies", `${record.id}`));
    });
  } catch (err) {
    console.log(err);
  }
};

export const UpdateUserProfileWithNewData = async (firebaseUserUUID) => {
  let userList = [];
  try {
    /**
     * Fetch All user data and save in list
     * */
    // check if this list already exists in db
    const querySnapshot = await getDocs(query(collection(db, "users")));
    //console.log(querySnapshot.docs);
    querySnapshot.forEach((doc) => {
      userList.push({
        id: doc.id,
        data: doc.data(),
      });
      //console.log(doc.data());
      // console.log(doc.id, " => ", doc.data());
    });

    console.log(userList);

    /**
     * Update above fetched profiles with new data & fields
     * */
    userList.forEach(async (userData, index) => {
      // do this for first 100 contacts
      if (index <= 100) {
        await updateDoc(doc(db, "users", `${userData.id}`), {
          // account: "business",
          salesforce: {
            instance_url: "",
            refresh_token: "",
            enabled: false,
          },
        });
        console.log(index, "->", userData.id);
      }
    });
    console.log("doc update done");
  } catch (err) {
    console.log(err);
  }
};

/**
 * Get saved integartions config for loggedIn user
 */
export const getUserSavedIntegrationSettings = async (firebaseUserUUID) => {
  var userData = {};
  try {
    // Get users data
    const docSnap = await getDoc(doc(db, "users", `${firebaseUserUUID}`));
    if (docSnap.exists()) {
      // if user exists update credit info
      // console.log("user document exists in db");
      //console.log(docSnap.data());
      let rawData = docSnap.data();
      userData["salesforce"] = rawData?.salesforce || "";
      userData["hubspot"] = rawData?.hubspot || "";
      userData["outreach"] = rawData?.outreach || "";
    } else {
      // doc.data() will be undefined in this case
      console.log("No user data!");
    }
  } catch (error) {
    console.error("Error adding document: ", error);
  }
  return userData;
};

/**
 * Save enrichment csv files
 * 1) create file name entry in one collection
 * 2) save all rows as firestore documents in another collection
 */

export const saveCSVFileForEnrichment = async (
  firebaseUserUUID,
  uploadedCSVFileName,
  combinedCSVData,
  fileStatus,
  generateLines
) => {
  try {
    // check if this CSV File already exists in db
    const querySnapshot = await getDocs(
      query(
        collection(db, "enrichment_csv_file_list"),
        where("firebase_auth_uuid", "==", firebaseUserUUID),
        where("fileName", "==", uploadedCSVFileName)
      )
    );

    // if such file is not saved already && incoming csv file is not empty, create csv file in list & start saving csv rows in seperate collection
    if (querySnapshot.docs.length === 0 && combinedCSVData.length !== 0) {
      await addDoc(collection(db, "enrichment_csv_file_list"), {
        fileName: `${uploadedCSVFileName}`,
        enrichmentComplete: false,
        isRunning: false,
        rowCount: `${combinedCSVData?.length}`,
        firebase_auth_uuid: `${firebaseUserUUID}`,
        uploadedAt: moment().toDate(),
        uploadType: "normal",
        scrapComplete: true,
        enrichmentStatus: fileStatus,
        generateLines: generateLines,
      });

      // use firebase batch to upload csv file rows a seperate objects
      let batch = writeBatch(db);

      for (let i = 1; i <= combinedCSVData.length; i++) {
        let csvRow = combinedCSVData[i - 1]
        const csvFileRowRef = doc(collection(db, "enrichment_csv_file_rows"));

        batch.set(csvFileRowRef, {
          email: `${csvRow.email}` || "",
          linkedInUrl: `${csvRow.linkedInUrl}` || "",
          firstName: `${csvRow.firstName}` || "",
          lastName: `${csvRow.lastName}` || "",
          companyName: `${csvRow.companyName}` || "",
          firebase_auth_uuid: `${firebaseUserUUID}`,
          fileName: `${uploadedCSVFileName}`,
          enriched: false,
        });

        // check to prevent firebase error : "maximum 500 writes allowed per request"
        if (i % 500 === 0) {
          await batch.commit();
          batch = writeBatch(db);
        }
      }

      await batch.commit();
    }
  } catch (err) {
    console.log("Failed while uploading csv file for enrichment...");
    console.log(err);
  }
};

/** fetched saved CSV files names for enrichment */

export const fetchSavedCSVFileListForEnrichment = async (firebaseUserUUID) => {
  let list = [];
  try {
    // check if this list already exists in db
    const querySnapshot = await getDocs(
      query(
        collection(db, "enrichment_csv_file_list"),
        where("firebase_auth_uuid", "==", firebaseUserUUID)
      )
    );
    console.log(querySnapshot.docs);
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      let id = doc.id;

      list.push({ id, ...data });
      // list.push(doc.data());
      //console.log(doc.data());
      // console.log(doc.id, " => ", doc.data());
    });

    //console.log(list);
  } catch (err) {
    console.log(err);
  }
  return list;
};

//fetches all the rows of a saved csv file for enrichment
export const fetchSavedCSVFileRows = async (firebaseUserUUID, fileName) => {
  let list = [];

  try {
    // check if this list already exists in db
    const querySnapshot = await getDocs(
      query(
        collection(db, "enrichment_csv_file_rows"),
        where("firebase_auth_uuid", "==", firebaseUserUUID),
        where("fileName", "==", fileName)
      )
    );

    querySnapshot.forEach((doc) => {
      list.push(doc.data());
    });

  } catch (err) {
    console.log(err);
  }
  return list;
};

/**
 * Deletes saved CSV files for enrichment
 * first deletes all the individual saved rows inside rows collection in bulk(efficent)
 * then deletes the csv file entry itself
 */
export const deleteSavedCSVFile = async (firebaseUserUUID, fileName) => {
  try {
    let batch = writeBatch(db);
    console.log("deleting ->", fileName);

    //check if content are not empty
    if (firebaseUserUUID.length > 0 && fileName.length > 0) {
      //first delete all the saved companies inside that list

      // get all the rows of file to delete
      const querySnapshot = await getDocs(
        query(
          collection(db, "enrichment_csv_file_rows"),
          where("firebase_auth_uuid", "==", firebaseUserUUID),
          where("fileName", "==", fileName)
        )
      );

      // prepare batch delete with their ids
      for (let i = 1; i <= querySnapshot.docs.length; i++) {
        let csvRow = querySnapshot.docs[i - 1]
        let csvFileRowRef = doc(db, "enrichment_csv_file_rows", `${csvRow.id}`);

        batch.delete(csvFileRowRef);

        // check to prevent firebase error : "maximum 500 writes allowed per request"
        if (i % 500 === 0) {
          //commit all delete of rows in one go
          await batch.commit();
          batch = writeBatch(db);
        }
      }

      //commit all delete of rows in one go
      await batch.commit();

      // Now delete the actual file entry(eg: fileName, rows, enrichementCompleted, uploadedAt etc )
      const listQuery = query(
        collection(db, "enrichment_csv_file_list"),
        where("firebase_auth_uuid", "==", firebaseUserUUID),
        where("fileName", "==", fileName)
      );
      const listQuerySnapshot = await getDocs(listQuery);
      listQuerySnapshot.forEach(async (record) => {
        let phantomId = record.data().phantomId;

        // delete phantom
        let deleteRes = await deletePhantom(phantomId);
        console.log(deleteRes);

        await deleteDoc(doc(db, "enrichment_csv_file_list", `${record.id}`));
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/** Onboarding */

// save leadzilla user company name
export const updateLeadzillaUserProductName = async (UserId, productName) => {
  try {
    const userDataRef = doc(db, "users", `${UserId}`);
    updateDoc(userDataRef, {
      product: `${productName}`,
    });
  } catch (err) {
    console.log(err);
  }
};

// save leadzilla user product description
export const updateLeadzillaUserProductDescription = async (
  UserId,
  productDescription
) => {
  try {
    const userDataRef = doc(db, "users", `${UserId}`);
    updateDoc(userDataRef, {
      productDescription: `${productDescription}`,
    });
  } catch (err) {
    console.log(err);
  }
};

// save leadzilla company email body
export const saveLeadzillaEmailBody = async (UserId, emailBody) => {
  console.log("running.....");
  try {
    const userDataRef = doc(db, "users", `${UserId}`);
    updateDoc(userDataRef, {
      emailBody: `${emailBody}`,
    });
  } catch (err) {
    console.log(err);
  }
};
/** Dev tool: Append a field in all document of a collection in firestore */
/**
 * Deletes an entrie company list
 * first deletes all the saved companies inside that list in bulk(efficent)
 * then deletes the company list itself
 */
export const performInBulk = async () => {
  try {
    const batch = writeBatch(db);

    console.log("appending ->");

    //first delete all the saved companies inside that list

    // check if this list already exists in db get their ids
    const querySnapshot = await getDocs(query(collection(db, "users")));

    // prepare batch to append data with their ids
    querySnapshot.forEach(async (record) => {
      console.log(record.data());
      console.log(record.id);

      if (record.data()?.onboardingComplete !== true) {
        let documentRef = doc(db, "users", `${record.id}`);
        batch.update(documentRef, {
          // account: "business",
          onboardingComplete: false,
        });
      }
    });

    //commit all firebase operation
    await batch.commit();
  } catch (err) {
    console.log(err);
  }
};

export const generateEmailBody = async (companyName, companyDescription, firebaseAuthUUID) => {
  /**
     * data contains paragraph as sample data, and other open ai
     * related setting taken directly from opan ai playground
     */

  var productData = {
    companyName: companyName,
    companyDescription: companyDescription,
    userId: firebaseAuthUUID,
  };

  let generatedEmailBody = {};

  try {
    await axios
      .post(`${serverURL}/email`, JSON.stringify(productData), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //Autherization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        let rawData = response?.data;
        let emailData = rawData?.choices[0]?.text?.split("Email body:");
        let emailBody = emailData[1];
        generatedEmailBody = emailBody;
      })
      .catch((error) => {
        console.log(error);
      });
    return generatedEmailBody;
  } catch (err) {
    console.log(err);
  }
}

export const getCredits = async (firebaseAuthId) => {
  const userRef = doc(db, "users", firebaseAuthId);
  const userDataDoc = await getDoc(userRef);
  const userData = userDataDoc.data();
  const { credits, personalisationCredits } = userData;

  return { credits, personalisationCredits };
};

export const getAccountType = async (firebaseAuthId) => {
  const userRef = doc(db, "users", firebaseAuthId);
  const userDataDoc = await getDoc(userRef);
  const userData = userDataDoc.data();
  const { account } = userData;

  return account;
}

export const getProductInfo = async (firebaseAuthId) => {
  const userRef = doc(db, "users", firebaseAuthId);
  const userDataDoc = await getDoc(userRef);
  const userData = userDataDoc.data();
  const { product, productDescription } = userData;

  return {
    productName: product,
    productDescription,
  };
};

export const getContacts = async (firebaseAuthId) => {
  const userRef = doc(db, "users", firebaseAuthId);
  const userDataDoc = await getDoc(userRef);
  const userData = userDataDoc.data();
  const { contacts } = userData;

  return contacts;
};

export const deletePhantom = async (phantomId = "") => {
  let response = {
    success: false,
    data: "",
  };

  await axios
    .post(
      "https://api.phantombuster.com/api/v2/agents/delete",
      { "id": phantomId.toString() },
      {
        headers: {
          "Content-Type": "application/json",
          'X-Phantombuster-Key': process.env.REACT_APP_PHANTOMBUSTER_API_KEY
        },
      }
    )
    .then((res) => {
      response.data = res?.data;
      response.success = true;
    })
    .catch((error) => {
      console.log("Failed while deleting agent...", error?.response?.data);
      response.success = false;
      return response;
    });

  return response;
};

export const updateSessionCookie = async (fileId, newSessionvalue) => {
  const fileRef = doc(db, "enrichment_csv_file_list", fileId);
  let fileDoc = await getDoc(fileRef);
  let fileData = fileDoc.data();
  let { phantomId, scrapUrl, phantomFileName } = fileData;

  // update session cookie in db
  await updateDoc(fileRef, {
    sessionCookie: newSessionvalue,
    isCookieUpdated: true,
    enrichmentStatus: "Scrapping...",
  });

  let params = {
    "id": phantomId,
    "org": "phantombuster",
    "scriptId": "6988",
    "script": "Sales Navigator Search Export.js",
    "branch": "master",
    "environment": "release",
    "argument": {
      "sessionCookie": newSessionvalue,
      "numberOfProfiles": 100,
      "extractDefaultUrl": false,
      "removeDuplicateProfiles": true,
      "searches": scrapUrl,
      "csvName": phantomFileName,
    }
  }

  const options = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      'X-Phantombuster-Key': process.env.REACT_APP_PHANTOMBUSTER_API_KEY
    }
  }

  // update session cookie in phantombuster
  await axios
    .post("https://api.phantombuster.com/api/v2/agents/save", params, options)
    .then((res) => {
      console.log("Session cookie updated successfully in phantombuster");
      console.log("Response from phantombuster: ", res?.data);
    })
    .catch((error) => {
      console.log("Failed while saving agent...\n", error?.response?.data?.error);
    });

  return;
};

export const getCreditHistory = async (firebaseAuthId) => {
  const userRef = doc(db, "credit_history", firebaseAuthId);
  const userDataDoc = await getDoc(userRef);
  const userData = userDataDoc.data();
  // const { creditHistory } = userData;

  return userData;
};