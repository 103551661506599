/**
 * Company contact search tab
 *
 */

import React, { useEffect, useState, useContext } from "react";
import { db, auth, fetchCompanyList, saveCompany } from "../../firebase-config";
import { arrayRemove, doc, onSnapshot, updateDoc } from "firebase/firestore";
import {
  useNavigate,
  Link,
  useSearchParams,
  useParams,
} from "react-router-dom";
import {
  Button,
  Table,
  Select,
  Drawer,
  Space,
  Divider,
  Spin,
  Tree,
  Tag,
  Tabs,
  Popover,
  Cascader,
  notification,
  Pagination,
  message
} from "antd";

import axios from "axios";
import {
  UserGroupIcon,
  LinkIcon,
  OfficeBuildingIcon,
  LibraryIcon,
  TrendingUpIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  StarIcon,
  UsersIcon,
  ServerIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { CSVLink } from "react-csv";
import LogRocket from "logrocket";

import { industryCascaderOptions, country_list } from "../../Utils/list.js";
import {
  FacebookFilled,
  LinkedinFilled,
  TwitterSquareFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { UserIdContext } from "../../Context/UserIdContext";
import { CreditCountContext } from "../../Context/CreditCountContext";
import { CompanySearchQueryContext } from "../../Context/CompanySearchQueryContext";
import CustomPagination from "../CustomPagination/CustomPagination";

export default function CompanySearch({ credits }) {
  // console.log("props credits...", credits);

  const [selectedOrgchartNodeKeys, setSelectedOrgchartNodeKeys] = useState([]);

  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  const onOrgchartNodeSelect = (selectedKeysValue, info) => {
    console.log("onSelect", info);
    setSelectedOrgchartNodeKeys(selectedKeysValue);
  };

  //filter selector values
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedCompanySize, setSelectedCompanySize] = useState([]);
  const [selectedCompanyRevenue, setSelectedCompanyRevenue] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);

  //autocomplete options
  const [fetchedDomainOption, setFetchedDomainOption] = useState([]);

  // advance filter selector values
  const [selectedTechnology, setSelectedTechnology] = useState([]);
  const [selectedSimilarTo, setSelectedSimilarTo] = useState([]);
  const [advanceSearchLimit, setAdvanceSearchLimit] = useState(15);
  const [advanceSearchCursorMark, setAdvanceSearchCursorMark] = useState(0);

  //filter setting states
  const [cursorMark, setCursorMark] = useState("");
  const [resultLimit, setResultLimit] = useState(15);
  const [totalContactsAvailable, setTotalContactsAvailable] = useState("");

  //misc states
  const [searchResult, setSearchResult] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState([]);

  //pagination
  const [paginationData, setPaginationData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const [savedCompanyList, setSavedCompanyList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [orgChartDrawerVisible, setOrgChartDrawerVisible] = useState(false);
  const [firebaseAuthUUID, setFirebaseAuthUUID] = useState("");

  //context
  const [UserId, setUserId] = useContext(UserIdContext);
  const { CreditCount, setCreditCount } = useContext(CreditCountContext);
  const [CompanySearchQuery, setCompanySearchQuery] = useContext(
    CompanySearchQueryContext
  );

  // const [accessToken, setAccessToken] = useState("");

  const { Option } = Select;
  var { search_type } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  var selectedCompanyList = "";
  // const serverURL = "http://localhost:6060";
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const clearbitAutocompleteAPIUrl =
    "https://autocomplete.clearbit.com/v1/companies/suggest";

  var credits = CreditCount;

  // useEffect(() => {
  //   // console.log("params", search_type);
  // }, [search_type]);

  // get filters from url use it to fetch latest contact data
  useEffect(() => {
    try {
      let filterFromUrl = JSON.parse(searchParams.get("filter"));
      // console.log("search query from URL", filterFromUrl);
      // if filter in url preset use it as search query

      if (
        filterFromUrl !== undefined &&
        filterFromUrl !== null &&
        filterFromUrl !== " "
      ) {
        setSelectedDomain(filterFromUrl.domain || []);
      }
      // otherwise fetch results for prev search query saved in context
      else {
        console.log("previous search query from context");
        console.log(CompanySearchQuery);
        //set filters to last saved seach query
        setSelectedCompany(CompanySearchQuery.companyName);
        setSelectedDomain(CompanySearchQuery.companyDomain);
        setSelectedIndustry(CompanySearchQuery.industry);
        setSelectedCompanySize(CompanySearchQuery.headcount);
        setSelectedCompanyRevenue(CompanySearchQuery.revenue);
        setSelectedCountry(CompanySearchQuery.country);
        //adavnce filters are not persisted as they cost credit for each search
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchSavedListNames();
  }, [UserId]);

  const fetchSavedListNames = async () => {
    try {
      // console.log("fetching saved list name");
      const list = await fetchCompanyList(UserId);
      setSavedCompanyList(list);
    } catch (err) { }
  };

  // saved company list options for selector
  const companyListOptions = [];
  savedCompanyList.forEach((country) => {
    companyListOptions.push(
      <Option key={country.listName}>{country.listName}</Option>
    );
  });

  //notification component
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  function handleSaveCompanySelectChange(value) {
    console.log(value);
    selectedCompanyList = value;
  }

  const testDataSource = [
    {
      key: "1",
      name: "",
      level: "C Level",
      count: "3",
      child: false,
      children: [
        {
          key: "mflmbvkj",
          name: "Vikas",
          role: "CMO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
        {
          key: "wqguygdb",
          name: "Maruti",
          role: "CEO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
        {
          key: "sanmnfkjfk",
          name: "Naina",
          role: "CTO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
      ],
    },
    {
      key: "2",
      name: "",
      level: "Director Level",
      count: "0",
      child: false,
    },
    {
      key: "3",
      name: "",
      level: "VP Level",
      count: "0",
      child: false,
    },
    {
      key: "4",
      name: "",
      level: "Manager Level",
      count: "3",
      child: false,
      children: [
        {
          key: "mflmbvkj",
          name: "Vikas",
          role: "CMO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
        {
          key: "wqguygdb",
          name: "Maruti",
          role: "CEO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
        {
          key: "sanmnfkjfk",
          name: "Naina",
          role: "CTO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
      ],
    },
    {
      key: "5",
      name: "",
      level: "Staff Level",
      count: "0",
      child: false,
    },
    {
      key: "6",
      name: "",
      level: "Others",
      count: "0",
      child: false,
    },
  ];

  const testColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render(text, record) {
        return {
          props: {
            style: {
              display: "flex",
              flexDirection: "row",
              justifyContent: "flexStart",
              alignItems: "flexStart",
              padding: "5px 15px",
              margin: "5px 5px",
              marginLeft: record.child ? "70px" : "5px",
              border: "1px solid #e2e2e2",
              borderRadius: "5px",
              minHeight: "50px",
              minWidth: "200px",
              lineHeight: "5px",
              boxShadow: "0px 0px 10px 5px rgba(200, 200, 200, 0.2)",
            },
          },
          children: (
            <div
              style={{
                padding: "5px 15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flexStart",
                alignItems: "flexStart",
              }}
            >
              <p>{record.name}</p>
              {record.level ? <p>{record.level}</p> : ""}
              <p>{record.count}</p>
              <p>{record.role}</p>

              {record.linkedInId ? (
                <a
                  href={"http://" + record.linkedInId}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  LinkedIn
                </a>
              ) : (
                ""
              )}
            </div>
          ),
        };
      },
    },
  ];

  const [CSVHeaders] = useState([
    { label: "fullName", key: "fullName" },
    { label: "firstName", key: "firstName" },
    { label: "lastName", key: "lastName" },
    { label: "companyName", key: "companyName" },
    { label: "website", key: "primaryDomain" },
    // { label: "verificationStatus", key: "emailStatus" },
    { label: "jobTitle", key: "title" },
    { label: "emailId", key: "emailAddress" },
    { label: "phoneNumber", key: "phoneNumber" },
    { label: "phoneDirect", key: "phoneDirect" },
    { label: "phoneCompany", key: "phoneCompany" },
    { label: "phoneMobile", key: "phoneMobile" },
    { label: "uniqueLinkedinId", key: "linkedInId" },
    { label: "twitterId", key: "twitterId" },
    { label: "facebookId", key: "facebookId" },
  ]);

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/**
           * Use clearbit api to fetch company logos using domain name
           */}
          <img
            style={{
              maxWidth: "30px",
              background: "#f6f6f6",
              marginBottom: "5px",
            }}
            src={
              "https://logo.clearbit.com/" + record.companyWebsite + "?size=30"
            }
            alt=""
          />

          {record.companyName ? (
            <Link to={`/company?domain=${record.companyWebsite}`}>
              <h1 className="company-name">{record.companyName}</h1>
            </Link>
          ) : (
            <Link to={`/company?domain=${record.companyWebsite}`}>
              <h1 className="company-name">
                {record.companyWebsite?.split(".")[0]}
              </h1>
            </Link>
          )}
          <p className="contact-role">{record.industry}</p>

          <span className="social-account-link-container">
            {record.linkedInId ? (
              <a
                href={"http://" + record.linkedInId}
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkedinFilled className="social-icons" color={"#4659ff"} />
              </a>
            ) : (
              ""
            )}
            {record.facebook ? (
              <a
                href={"http://" + record.facebook}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookFilled className="social-icons" color={"#4659ff"} />
              </a>
            ) : (
              ""
            )}
            {record.twitter ? (
              <a
                href={"http://" + record.twitter}
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterSquareFilled
                  className="social-icons"
                  color={"#4659ff"}
                />
              </a>
            ) : (
              ""
            )}
          </span>
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      render: (text, record, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {record.city || record.country ? (
            <div className="company-address">
              <p className="company-details">
                {record.city}, {" " + record.country}
              </p>
            </div>
          ) : (
            ""
          )}

          <p className="company-details">{record.industry}</p>

          <div>
            {record.subIndustry?.map((subInd, index) => {
              return (
                <p className="contact-department" key={index}>
                  {subInd}
                </p>
              );
            })}
          </div>

          {record.companyHeadCount ? (
            <p className="company-details">
              {record.companyHeadCount} employees
            </p>
          ) : (
            ""
          )}

          {record.companyRevenue ? (
            <p className="company-details">{record.companyRevenue} revenue</p>
          ) : (
            ""
          )}

          <a
            href={"http://" + record.primaryDomain}
            rel="noopener noreferrer"
            target="_blank"
            className="company-url"
          >
            {record.primaryDomain}
          </a>
        </div>
      ),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text, record, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {
            <h1 className="contact-department" style={{ marginBottom: "10px" }}>
              {record.numberOfContacts} employee contacts
            </h1>
          }

          {record.phoneDirect && record.phoneDirect !== "" ? (
            <div className="contact-container">
              <p className="contact-type">Direct</p>
              <p className="contact-data">{record.phoneDirect}</p>
            </div>
          ) : (
            ""
          )}
          {record.phoneCompany && record.phoneCompany !== "" ? (
            <div className="contact-container">
              <p className="contact-type">Company</p>
              <p className="contact-data">{record.phoneCompany}</p>
            </div>
          ) : (
            ""
          )}
          {record.phoneMobile && record.phoneMobile !== "" ? (
            <div className="contact-container">
              <p className="contact-type">Mobile</p>
              <p className="contact-data">{record.phoneMobile}</p>
            </div>
          ) : (
            ""
          )}

          {record.phoneNumber && record.phoneNumber !== "" ? (
            <div className="contact-container">
              <p className="contact-type">Other</p>
              <p className="contact-data">{record.phoneNumber}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/** save company to list */}
          <Popover
            title={"Save to track list"}
            style={{ width: "30px" }}
            placement="rightTop"
            trigger="hover"
            content={
              <>
                <Select
                  bordered={true}
                  allowClear
                  placeholder="Select a company list"
                  onChange={handleSaveCompanySelectChange}
                  style={{ width: "100%", margin: "5px 10px 10px 5px" }}
                >
                  {companyListOptions}
                </Select>

                <Button
                  style={{ margin: "2px 5px" }}
                  // className="secondary-button-active"
                  onClick={async (e) => {
                    // purchaseContact(index, record.id);
                    const isCompanySaved = await saveCompany(
                      UserId,
                      selectedCompanyList,
                      record.companyWebsite?.split(".")[1] +
                      "." +
                      record.companyWebsite?.split(".")[2],
                      record.companyWebsite?.split(".")[1]
                    );

                    console.log(
                      UserId,
                      selectedCompanyList,
                      record.companyWebsite?.split(".")[1] +
                      "." +
                      record.companyWebsite?.split(".")[2],
                      record.companyWebsite?.split(".")[1]
                    );

                    if (isCompanySaved === true) {
                      openNotificationWithIcon(
                        "success",
                        "Saved",
                        "Company saved for tracking"
                      );
                    } else {
                      openNotificationWithIcon(
                        "error",
                        "Not saved",
                        "Could not save this company for tracking, contact support"
                      );
                    }
                  }}
                >
                  Save
                </Button>
              </>
            }
          >
            <span>
              <button
                className="primary-button"
                style={{
                  margin: "2px 5px",
                  padding: "0px 20px",
                  width: "160px",
                }}
              >
                Track company
              </button>
            </span>
          </Popover>

          <Link
            to={`/search/people?filter={"domain":["${record.companyWebsite}"]}`}
          >
            <button
              style={{
                margin: "2px 5px",
                padding: "0px 20px",
                width: "160px",
              }}
              className="secondary-button-active"
            >
              View employees
            </button>
          </Link>
          {/* <button
            style={{ margin: "2px 5px" }}
            className="secondary-button-active"
            onClick={(e) => {
              showOrgChart(
                record.level,
                record.companyName,
                record.primaryDomain
              );
              console.log("button-clicked");
            }}
          >
            Org Chart
          </button> */}
        </div>
      ),
    },
  ];

  //User data row selection logic
  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
      // save selected user data rows
      setSelectedUserData(selectedRows);
    },
    // onSelect: (record, selected, selectedRows) => {
    //   console.log(record, selected, selectedRows);
    // },
    // onSelectAll: (selected, selectedRows, changeRows) => {
    //   console.log(selected, selectedRows, changeRows);
    // },
  };

  const countryOptions = [];
  country_list.forEach((country) => {
    countryOptions.push(<Option key={country.Name}>{country.Name}</Option>);
  });

  function customSelectTagUI(props) {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"green"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          fontWeight: "500",
          color: "green",
          margin: "3px 3px 3px 0px",
        }}
      >
        {label}
      </Tag>
    );
  }

  const industryOptions = [
    <Option key={"Agriculture & Mining"}>{"Agriculture & Mining"}</Option>,
    <Option key={"Business Services"}>{"Business Services"}</Option>,
    <Option key={"Computers & Electronics"}>
      {"Computers & Electronics"}
    </Option>,
    <Option key={"Consumer Services"}>{"Consumer Services"}</Option>,
    <Option key={"Education"}>{"Education"}</Option>,
    <Option key={"Energy & Utilities"}>{"Energy & Utilities"}</Option>,
    <Option key={"Financial Services"}>{"Financial Services"}</Option>,
    <Option key={"Government"}>{"Government"}</Option>,
    <Option key={"Healthcare, Pharmaceuticals, & Biotech"}>
      {"Healthcare, Pharmaceuticals, & Biotech"}
    </Option>,
    <Option key={"Manufacturing"}>{"Manufacturing"}</Option>,
    <Option key={"Media & Entertainment"}>{"Media & Entertainment"}</Option>,
    <Option key={"Non-Profit"}>{"Non-Profit"}</Option>,
    <Option key={"Other"}>{"Other"}</Option>,
    <Option key={"Real Estate & Construction"}>
      {"Real Estate & Construction"}
    </Option>,
    <Option key={"Retail"}>{"Retail"}</Option>,
    <Option key={"Software & Internet"}>{"Software & Internet"}</Option>,
    <Option key={"Telecommunications"}>{"Telecommunications"}</Option>,
    <Option key={"Transportation & Storage"}>
      {"Transportation & Storage"}
    </Option>,
    <Option key={"Travel, Recreation, and Leisure"}>
      {"Travel, Recreation, and Leisure"}
    </Option>,
    <Option key={"Wholesale & Distribution"}>
      {"Wholesale & Distribution"}
    </Option>,
  ];

  const companySizeOptions = [
    <Option key={"0 - 25"}>{"0 - 25"}</Option>,
    <Option key={"25 - 100"}>{"25 - 100"}</Option>,
    <Option key={"100 - 250"}>{"100 - 250"}</Option>,
    <Option key={"250 - 1000"}>{"250 - 1000"}</Option>,
    <Option key={"1k - 10k"}>{"1k - 10k"}</Option>,
    <Option key={"10k - 50k"}>{"10k - 50k"}</Option>,
    <Option key={"50k - 100k"}>{"50k - 100k"}</Option>,
    <Option key={"> 100k"}>{"> 100k"}</Option>,
  ];

  const companyRevenueOptions = [
    <Option key={"$0 - 1M"}>{"$0 - 1M"}</Option>,
    <Option key={"$1 - 10M"}>{"$1 - 10M"}</Option>,
    <Option key={"$10 - 50M"}>{"$10 - 50M"}</Option>,
    <Option key={"$50 - 100M"}>{"$50 - 100M"}</Option>,
    <Option key={"$100 - 250M"}>{"$100 - 250M"}</Option>,
    <Option key={"$250 - 500M"}>{"$250 - 500M"}</Option>,
    <Option key={"$500M - 1B"}>{"$500M - 1B"}</Option>,
    <Option key={"> $1B"}>{"> $1B"}</Option>,
  ];

  const resultFetchLimitOptions = [
    <Option key={15}>15</Option>,
    <Option key={25}>25</Option>,
    <Option key={50}>50</Option>,
    <Option key={75}>75</Option>,
    <Option key={100}>100</Option>,
  ];

  // Credit decrement for similar companies & technographics
  const decrementCredits = async (creditsToDeduct) => {
    if (UserId && CreditCount > 0) {
      const userDataRef = doc(db, "users", `${UserId}`);
      updateDoc(userDataRef, {
        credits: `${credits - creditsToDeduct}`,
      });
    }
    credits = `${credits - creditsToDeduct}`;
  };

  const fetchSearchResult = async (
    selectedSimilarTo,
    selectedTechnology,
    selectedCompany,
    selectedDomain,
    selectedCompanySize,
    selectedCompanyRevenue,
    selectedIndustry,
    selectedCountry,
    resultLimit,
    cursorMark,
    fetchNext
  ) => {
    console.log("fetching company search ****----***", cursorMark);

    let industry = [],
      subIndustry = [];

    selectedIndustry.forEach((filter) => {
      console.log(filter);
      if (filter.length > 1) {
        subIndustry.push(filter[1]);
      } else {
        industry.push(filter[0]);
      }
    });

    var data = {
      similarTo: selectedSimilarTo,
      technographics: selectedTechnology,
      companyName: selectedCompany,
      nameDomain: selectedDomain,
      numberOfEmployees: selectedCompanySize,
      revenue: selectedCompanyRevenue,
      industryName: industry,
      subIndustry: subIndustry,
      city: [],
      country: selectedCountry,
      state: [],
      limit: resultLimit || 25,
      cursorMark: fetchNext === true ? cursorMark : "",
    };

    try {
      setLoading(true);

      if (credits > 0) {
        await axios
          .post(`${serverURL}/search/company`, JSON.stringify(data), {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Autherization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            var searchResultData = response.data.result;

            console.log("company search result : ", searchResultData);
            //setSearchResult(searchResultData);

            let combinedData = [];

            // if next page requested append data and persist locally, else clear local data and append new data
            if (fetchNext === true) {
              combinedData = [...paginationData, ...searchResultData];
            } else {
              combinedData = [...searchResultData];
            }

            // console.log("new pagination data", combinedData);
            setPaginationData(combinedData);
            setSelectedUserData([]);
            setCursorMark(response.data.cursorMark);
            setTotalContactsAvailable(response.data.hits);

            //update credits for adavance filter usage
            if (searchResultData.length > 0) {
              if (selectedSimilarTo.length > 0) {
                let creditsToDeduct = searchResultData.length || 0;
                decrementCredits(creditsToDeduct);
              }
              if (selectedTechnology.length > 0) {
                let creditsToDeduct = searchResultData.length || 0;
                decrementCredits(creditsToDeduct);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        message.info("Don't have enough Contact credits");
        console.log("credits not enough");
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  //clears selected filters at once
  const clearFilters = async () => {
    //empty saved context filter
    setCompanySearchQuery({
      companyName: [],
      companyDomain: [],
      industry: [],
      headcount: [],
      revenue: [],
      country: [],
      similarTo: [],
      technology: [],
    });
    setSelectedCompany([]);
    setSelectedDomain([]);
    setSelectedIndustry([]);
    setSelectedCompanySize([]);
    setSelectedCompanyRevenue([]);
    setSelectedCountry([]);
    setSelectedSimilarTo([]);
    setSelectedTechnology([]);
  };

  const fetchNextPage = () => {
    // if no next page stored locally then fetch new page from backend

    //if data is available on next page local move to it
    let nextPageContent = paginationData.slice(
      (pageNumber + 1) * resultLimit,
      (pageNumber + 1) * resultLimit + resultLimit
    );
    console.log(nextPageContent);

    if (nextPageContent.length > 0) {
      console.log("data available on next page");
      setPageNumber(pageNumber + 1);
      console.log(pageNumber + 1);
    } else {
      //else fetch new data from backend
      // console.log("fetch new data");
      let fetchNext = true;
      fetchSearchResult(
        selectedSimilarTo,
        selectedTechnology,
        selectedCompany,
        selectedDomain,
        selectedCompanySize,
        selectedCompanyRevenue,
        selectedIndustry,
        selectedCountry,
        resultLimit,
        cursorMark,
        fetchNext
      );
      //increment page number
      setPageNumber(pageNumber + 1);
      console.log(pageNumber + 1);
    }
  };

  // go to previous page using local persisted data
  const previousPage = () => {
    if (pageNumber >= 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  // go to a specific page number using local persisted data
  const moveToPage = (pageToMove) => {
    //check if there is page data & we are not already on requested page
    if (pageToMove >= 0) {
      setPageNumber(pageToMove);
    }
  };

  // respond to changes on filter values
  useEffect(() => {
    let fetchNext = false;
    if (
      selectedSimilarTo.length > 0 ||
      selectedTechnology.length > 0 ||
      selectedIndustry.length > 0 ||
      selectedCompanySize.length > 0 ||
      selectedCompanyRevenue.length > 0 ||
      selectedDomain.length > 0 ||
      selectedCompany.length > 0 ||
      selectedCountry.length > 0
    ) {
      // on filter change set current page to page 0
      setPageNumber(0);

      fetchSearchResult(
        selectedSimilarTo,
        selectedTechnology,
        selectedCompany,
        selectedDomain,
        selectedCompanySize,
        selectedCompanyRevenue,
        selectedIndustry,
        selectedCountry,
        resultLimit,
        cursorMark,
        fetchNext
      );
      // console.log(selectedIndustry);
      // console.log(selectedCompanySize);
      // console.log(selectedCompanyRevenue);
      // console.log(selectedDomain);
      // console.log(selectedCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSimilarTo,
    selectedTechnology,
    selectedIndustry,
    selectedCompanySize,
    selectedCompanyRevenue,
    selectedDomain,
    selectedCompany,
    selectedCountry,
    resultLimit,
  ]);

  const [treeData, setTreeData] = useState([
    {
      level: "C-Level",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },
    {
      level: "VP-Level",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },
    {
      level: "Director-Level",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },

    {
      level: "Manager-Level",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },

    {
      level: "Staff",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },
    {
      level: "Other",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },
  ]);

  const onClose = () => {
    setOrgChartDrawerVisible(false);
  };

  // handle changes on filter selectors
  function handleIndustryChange(value) {
    //console.log(value);

    setSelectedIndustry(value);
    //update filter value in search query in context
    let searchQuery = CompanySearchQuery;
    searchQuery["industry"] = value;
    setCompanySearchQuery(searchQuery);
  }
  function handleCompanySizeChange(value) {
    setSelectedCompanySize(value);
    //update filter value in search query in context
    let searchQuery = CompanySearchQuery;
    searchQuery["headcount"] = value;
    setCompanySearchQuery(searchQuery);
  }
  function handleCompanyRevenueChange(value) {
    setSelectedCompanyRevenue(value);
    //update filter value in search query in context
    let searchQuery = CompanySearchQuery;
    searchQuery["revenue"] = value;
    setCompanySearchQuery(searchQuery);
  }
  function handleDomainChange(value) {
    setSelectedDomain(value);
    //update filter value in search query in context
    let searchQuery = CompanySearchQuery;
    searchQuery["companyDomain"] = value;
    setCompanySearchQuery(searchQuery);
  }

  function handleCompanyChange(value) {
    setSelectedCompany(value);
    //update filter value in search query in context
    let searchQuery = CompanySearchQuery;
    searchQuery["companyName"] = value;
    setCompanySearchQuery(searchQuery);
  }
  function handleCountryChange(value) {
    setSelectedCountry(value);
    //update filter value in search query in context
    let searchQuery = CompanySearchQuery;
    searchQuery["country"] = value;
    setCompanySearchQuery(searchQuery);
  }
  function handleResultLimitChange(value) {
    setResultLimit(value);
  }

  // handle changes on adavance filter selectors
  function handleTechnologyChange(value) {
    setSelectedTechnology(value);
    //update filter value in search query in context
    let searchQuery = CompanySearchQuery;
    searchQuery["technology"] = value;
    setCompanySearchQuery(searchQuery);
  }

  function handleSimilarToChange(value) {
    setSelectedSimilarTo(value);
    //update filter value in search query in context
    let searchQuery = CompanySearchQuery;
    searchQuery["similarTo"] = value;
    setCompanySearchQuery(searchQuery);
  }

  const handleDomainSearch = async (value) => {
    await autocompleteCompany(value);
  };

  // Use clearbit autocomplete for company-name, domain & logo
  const autocompleteCompany = async (searchKeyword) => {
    console.log(searchKeyword);
    try {
      if (searchKeyword?.length > 0) {
        await axios
          .get(`${clearbitAutocompleteAPIUrl}?query=${searchKeyword}`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            console.log(response.data);
            setFetchedDomainOption(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const exportData = (selectedUserData) => {
    console.log("exporting :", selectedUserData);
  };

  return (
    <>
      <h1>Company search</h1>
      <div className="search-cards-container">
        {/** Search filter card */}
        <div className="search-filter-card">
          {/**clear filter button appear when any filter selected */}
          <div className="filter-header-cta-container">
            {selectedIndustry.length > 0 ||
              selectedCompanySize.length > 0 ||
              selectedCompanyRevenue.length > 0 ||
              selectedDomain.length > 0 ||
              selectedCompany.length > 0 ||
              selectedCountry.length > 0 ||
              selectedSimilarTo.length > 0 ||
              selectedTechnology.length > 0 ? (
              <>
                <button
                  className="secondary-button-active"
                  style={{ margin: "-20px 10px 10px 0px" }}
                  onClick={() => {
                    clearFilters();
                  }}
                >
                  Clear
                </button>
                <Divider style={{ marginTop: "0px" }} />
              </>
            ) : (
              ""
            )}
          </div>

          <h1 className="filter-heading">COMPANY</h1>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <OfficeBuildingIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Company name</h1>
            </span>
            <Select
              bordered={false}
              mode="tags"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Enter company name"
              value={selectedCompany}
              onChange={handleCompanyChange}
              onSearch={handleDomainSearch}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
              listItemHeight={10}
              listHeight={500}
              width={500}
            >
              {fetchedDomainOption.map((company) => (
                <Option value={company.name} label={company.name}>
                  <div
                    className="company-filter-option"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "40px",
                        background: "#f6f6f6",
                        marginRight: "20px",
                      }}
                      src={company.logo}
                      alt=""
                    />
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <p
                        style={{
                          color: "#151417",
                          lineHeight: "13px",
                          fontSize: "12px",
                          margin: "1px",
                          fontWeight: "500",
                        }}
                      >
                        {company.name}
                      </p>
                      <p
                        style={{
                          color: "#8b8b8b",
                          lineHeight: "13px",
                          fontSize: "12px",
                          margin: "1px",
                          fontWeight: "500",
                        }}
                      >
                        {company.domain}
                      </p>
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <LinkIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Company website</h1>
            </span>
            <Select
              bordered={false}
              mode="tags"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Enter company website"
              onChange={handleDomainChange}
              onSearch={handleDomainSearch}
              value={selectedDomain}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
              listItemHeight={10}
              listHeight={500}
              width={500}
            >
              {fetchedDomainOption.map((company) => (
                <Option label={company.domain} value={company.domain}>
                  <div
                    className="company-filter-option"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "40px",
                        background: "#f6f6f6",
                        marginRight: "20px",
                      }}
                      src={company.logo}
                      alt=""
                    />
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <p
                        style={{
                          color: "#151417",
                          lineHeight: "13px",
                          fontSize: "12px",
                          margin: "1px",
                          fontWeight: "500",
                        }}
                      >
                        {company.name}
                      </p>
                      <p
                        style={{
                          color: "#8b8b8b",
                          lineHeight: "13px",
                          fontSize: "12px",
                          margin: "1px",
                          fontWeight: "500",
                        }}
                      >
                        {company.domain}
                      </p>
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
          </span>

          {/* <span className="search-filter-container">
            <span className="filter-label-container">
              <LibraryIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Industry</h1>
            </span>
            <Select
              bordered={false}
              mode="multiple"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Select industry"
              onChange={handleIndustryChange}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            >
              {industryOptions}
            </Select>
          </span> */}

          <span className="search-filter-container">
            <span className="filter-label-container">
              <LibraryIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Industry</h1>
            </span>
            <Cascader
              bordered={false}
              multiple
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Select industry"
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
              options={industryCascaderOptions}
              value={selectedIndustry}
              onChange={handleIndustryChange}
              showSearch={(inputValue, path) =>
                path.some(
                  (option) =>
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) > -1
                )
              }
            />
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <UserGroupIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Headcount</h1>
            </span>
            <Select
              bordered={false}
              mode="multiple"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Select employee headcount"
              onChange={handleCompanySizeChange}
              value={selectedCompanySize}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            >
              {companySizeOptions}
            </Select>
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <TrendingUpIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Revenue</h1>
            </span>
            <Select
              bordered={false}
              mode="multiple"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Select company revenue"
              onChange={handleCompanyRevenueChange}
              value={selectedCompanyRevenue}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            >
              {companyRevenueOptions}
            </Select>
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <LocationMarkerIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Country</h1>
            </span>
            <Select
              bordered={false}
              mode="multiple"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Enter country of company"
              onChange={handleCountryChange}
              value={selectedCountry}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            >
              {countryOptions}
            </Select>
          </span>

          <Divider />
          <h1 className="filter-heading">
            Advanced filters (1 Credit/Company)
          </h1>
          <span
            className="search-filter-container"
            style={{ background: "transparent" }}
          >
            <span className="filter-label-container">
              <UsersIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Similar companies</h1>
            </span>
            <Select
              bordered={false}
              mode="tags"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              onSearch={handleDomainSearch}
              allowClear
              value={selectedSimilarTo}
              placeholder="Eg: ikea.com"
              onChange={handleSimilarToChange}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
              listItemHeight={10}
              listHeight={500}
              width={500}
            >
              {fetchedDomainOption.map((company) => (
                <Option label={company.domain} value={company.domain}>
                  <div
                    className="company-filter-option"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "40px",
                        background: "#f6f6f6",
                        marginRight: "20px",
                      }}
                      src={company.logo}
                      alt=""
                    />
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <p
                        style={{
                          color: "#151417",
                          lineHeight: "13px",
                          fontSize: "12px",
                          margin: "1px",
                          fontWeight: "500",
                        }}
                      >
                        {company.name}
                      </p>
                      <p
                        style={{
                          color: "#8b8b8b",
                          lineHeight: "13px",
                          fontSize: "12px",
                          margin: "1px",
                          fontWeight: "500",
                        }}
                      >
                        {company.domain}
                      </p>
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
          </span>
          <span
            className="search-filter-container"
            style={{ background: "transparent" }}
          >
            <span className="filter-label-container">
              <ServerIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Technology</h1>
            </span>
            <Select
              bordered={false}
              mode="tags"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              value={selectedTechnology}
              placeholder="Eg: shopify"
              onChange={handleTechnologyChange}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            />
          </span>
        </div>

        {/** Search result card */}
        <div className="search-result-card">
          <div className="search-result-card-controls">
            {totalContactsAvailable ? (
              <p
                className="available-contact-count"
                style={{ display: "block" }}
              >
                {totalContactsAvailable} companies
              </p>
            ) : (
              ""
            )}

            <CSVLink
              data={selectedUserData}
              headers={CSVHeaders}
              filename={"leadzilla-console-export.csv"}
              className="export-csv-button"
              target="_blank"
              style={{ display: "none" }}
            >
              Export CSV
            </CSVLink>

            <Button
              style={{ display: "none", margin: "0px 10px" }}
              onClick={() => {
                exportData(selectedUserData);
              }}
            >
              Export CSV
            </Button>

            <Select
              bordered={true}
              defaultValue={15}
              onChange={handleResultLimitChange}
              style={{ width: "80px", margin: "0px 10px 0px 5px" }}
            >
              {resultFetchLimitOptions}
            </Select>

            <button
              className="secondary-button-inactive"
              style={{ margin: "0px 2px" }}
              onClick={() => {
                previousPage();
              }}
            >
              Prev
            </button>

            <button
              className="secondary-button-inactive"
              style={{ margin: "0px 2px" }}
              onClick={() => {
                fetchNextPage();
              }}
            >
              Next
            </button>
          </div>

          <Table
            headers={false}
            style={{ padding: "0px 30px" }}
            size="large"
            columns={columns}
            loading={{
              indicator: (
                <LoadingOutlined
                  style={{
                    fontSize: 40,
                    fontWeight: 600,
                  }}
                />
              ),
              spinning: loading,
            }}
            rowKey="id"
            rowSelection={{ ...rowSelection }}
            //dataSource={[...searchResult]}
            dataSource={paginationData.slice(
              pageNumber * resultLimit,
              pageNumber * resultLimit + resultLimit
            )}
            // pagination={{ pageSize: 100 }}
            pagination={false}
            scroll={{ y: "max-content" }}
          />

          {
            paginationData.length > 0 &&
            <CustomPagination totalPages={
              Math.ceil(paginationData.length / resultLimit)
            }
              activePage={pageNumber}
              prevFunc={previousPage}
              nextFunc={fetchNextPage}
              moveToPage={moveToPage}
            />
          }

          {/* <Pagination
            current={pageNumber + 1}
            total={paginationData.length}
            defaultPageSize={resultLimit}
            // pageSize={resultLimit}
            disabled={paginationData?.length === 0 ? true : false}
            style={{ marginTop: "50px", marginLeft: "50px" }}
            onChange={(page, pageSize) => {
              console.log("page....", page);
              moveToPage(page - 1);
            }}
          /> */}
        </div>

        {/** Org chart UI */}
        <Drawer
          title={"Org Chart"}
          placement="right"
          size={"large"}
          onClose={onClose}
          visible={orgChartDrawerVisible}
          extra={
            <Space>{/* <p>Tip: Use drag-drop and zoom gesture </p> */}</Space>
          }
        >
          {/* <Orgchart /> */}
          <Table
            showHeader={false}
            pagination={false}
            dataSource={testDataSource}
            columns={testColumns}
          />
          <Tree
            defaultSelectedKeys={["0-0-0"]}
            showLine={true}
            showIcon={false}
            treeData={treeData}
            onSelect={onOrgchartNodeSelect}
            selectedKeys={selectedOrgchartNodeKeys}
            titleRender={(treeNode) => {
              return (
                <>
                  <span
                    className="title"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px 10px",
                      border: "1px solid #e2e2e2",
                      borderRadius: "5px",
                      minHeight: "50px",
                      minWidth: "200px",
                      boxShadow: "0px 0px 10px 5px rgba(200, 200, 200, 0.2)",
                    }}
                  >
                    <h4 className="contact-name">{treeNode.fullName}</h4>
                    <span className="contact-role">{treeNode.title}</span>
                    <span className="contact-role">{treeNode.level}</span>
                    <span className="contact-name">{treeNode.count}</span>

                    {treeNode.linkedInId ? (
                      <a
                        href={"http://" + treeNode.linkedInId}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        LinkedIn
                      </a>
                    ) : (
                      ""
                    )}
                  </span>
                </>
              );
            }}
          />
        </Drawer>
      </div>
    </>
  );
}
