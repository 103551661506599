/**
Stores latest people search filter values
 */

import React, { useState, createContext } from "react";

export const PeopleSearchQueryContext = createContext();

export const PeopleSearchQueryContextProvider = (props) => {
  const [PeopleSearchQuery, setPeopleSearchQuery] = useState({
    companyName: [],
    companyDomain: [],
    industry: [],
    headcount: [],
    revenue: [],
    country: [],
    title: [],
    seniority: [],
    department: [],
    fullName: [],
  });

  return (
    <PeopleSearchQueryContext.Provider
      value={[PeopleSearchQuery, setPeopleSearchQuery]}
    >
      {props.children}
    </PeopleSearchQueryContext.Provider>
  );
};
