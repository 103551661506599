import React, { useState, useContext, useEffect } from 'react';
import styles from "./Options.module.css";
import axios from "axios";
import Topnav from "../../Components/Topnav/Topnav";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { UserIdContext } from "../../Context/UserIdContext";
import { CreditCountContext } from "../../Context/CreditCountContext";
import {
    db,
    auth,
    generateEmailBody,
    getCreditHistory,
} from "../../firebase-config";
import { doc, onSnapshot } from "firebase/firestore";
import { CubeIcon } from "@heroicons/react/outline";
import { CurrencyDollarIcon } from "@heroicons/react/solid";
import {
    Alert,
    Button,
    Modal,
    Tag,
    Spin,
    Table,
    Form,
    Upload,
    Input,
    Select,
    message,
    Divider,
    Checkbox,
    Tooltip
} from "antd";
import {
    DeleteOutlined,
    LoadingOutlined,
    PlusOutlined,
    EditOutlined,
    TagOutlined
} from "@ant-design/icons";
import { v4 as uuidv4 } from 'uuid';
import { CSVLink } from 'react-csv';

const Options = () => {
    const serverURL = process.env.REACT_APP_SERVER_URL;
    var accessToken = "";
    var currentCredit = 0;
    var salesforceSetupUrl = "";
    let navigate = useNavigate();

    const [loggedInUser, setLoggedInUser] = useState(false);
    const [firebaseAuthUUID, setFirebaseAuthUUID] = useState("");
    const [UserId, setUserId] = useContext(UserIdContext);
    const { CreditCount, setCreditCount } = useContext(CreditCountContext);
    const [optionsType, setOptionsType] = useState("product");
    const [loading, setLoading] = useState(false);
    const [productList, setProductList] = useState([]);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [productModalVisible, setProductModalVisible] = useState(false)
    const [productEditModal, setProductEditModal] = useState(false)
    const [productDataForm] = Form.useForm();
    const [editDataForm] = Form.useForm();
    const [defaultChecked, setDefaultChecked] = useState(true);
    const [defaultDisabled, setDefaultDisabled] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [editItemId, setEditItemID] = useState("");
    const [exportYear, setExportYear] = useState(0);
    const [exportMonth, setExportMonth] = useState(0);
    const [exportData, setExportData] = useState([]);
    const [exportHeaders, setExportHeaders] = useState(["Date", "Time", "Description", "Credits deducted", "AI credits left", "Contact credits left"]);
    const [userHistory, setUserHistory] = useState({});

    onAuthStateChanged(auth, async (user) => {
        if (user) {
            //console.log("user logged in");

            //fetch and saved firebase auth access token
            await user.getIdToken().then((token) => {
                // setAccessToken(token);
                accessToken = token;
                //console.log(token);
            });

            setLoggedInUser(user);
            setFirebaseAuthUUID(user.uid);
            setUserId(user.uid);

            salesforceSetupUrl = `https://leadzilla-dev-ed.my.salesforce.com/services/oauth2/authorize?client_id=3MVG95mg0lk4batiqacSAs_HER2CCOTyocoK5omFHsmm56xdJNCvTUDPAyHNKogAJHyzudoZaog2.3VXPKJbl&redirect_uri=http://localhost:6060/salesforce-leadzilla-redirect&response_type=code&state=${firebaseAuthUUID}`;

            console.log(salesforceSetupUrl);

            onSnapshot(doc(db, "users", `${user.uid}`), (doc) => {
                //console.log("Current data: ", doc.data());
                currentCredit = doc.data().credits;
                setCreditCount(currentCredit);
                //console.log("credit Count", currentCredit);
            });
        } else {
            console.log("user not logged in");
            navigate("/login");
        }
    });

    const handleOptionChange = async (type) => {

        setOptionsType(type);

        // reset export month and year
        setExportMonth(0);
        setExportYear(0);

        if (type === "transactions") {
            let data = await getCreditHistory(UserId);
            setUserHistory({ ...data });
        }
    }

    // product table column schema
    const productListTableColumn = [
        {
            title: "Product name",
            dataIndex: "product",
            key: "product",
            render: (record, index) => (
                <div
                    style={{ display: "flex", flexDirection: "column", maxWidth: "90%", }}
                    key={uuidv4()}
                >
                    {/* <Link to={`/track/company/${record}`}>{record}</Link> */}
                    <p style={{ fontWeight: "500" }}>{record}</p>
                </div>
            ),
        },
        {
            title: "Status",
            key: "default",
            dataIndex: "default",
            render: (record, index) => (
                <>
                    {
                        record === true &&
                        <Tag
                            key={uuidv4()}
                            color={"green"}
                            style={{
                                fontWeight: "500",
                                margin: "3px 3px 3px 0px",
                            }}
                        >
                            {"Default"}
                        </Tag>
                    }
                </>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (record, index) => (
                <>
                    <Tooltip title="Edit" key={uuidv4()}>
                        <button
                            className="secondary-button-inactive"
                            style={{ margin: "0px 5px" }}
                            onClick={async () => {
                                console.log("index: ", index);
                                handleEditModalAndAutoFill(record);
                            }}
                        >
                            <EditOutlined />
                        </button>
                    </Tooltip>
                    <Tooltip title="Make default" key={uuidv4()}>
                        <button
                            className="secondary-button-inactive"
                            style={{ margin: "0px 5px" }}
                            onClick={async () => {
                                await updateDefaultProduct(record.productId)
                                await fetchProductList();
                            }}
                        >
                            <TagOutlined />
                        </button>
                    </Tooltip>
                    <Tooltip title="Delete" key={uuidv4()}>
                        <button
                            className="secondary-button-inactive"
                            style={{ margin: "0px 5px" }}
                            onClick={async () => {
                                await deleteProduct(record.productId)
                                await fetchProductList();
                            }}
                        >
                            <DeleteOutlined />
                        </button>
                    </Tooltip>
                </>
            ),
        },
    ]

    // fetch users product list from db
    const fetchProductList = async () => {
        let response = [];

        try {
            await axios.get(`${process.env.REACT_APP_SERVER_URL}/userProductData/${UserId}`)
                .then((res) => {
                    response = res.data;
                })
        } catch (error) {
            console.log(error);
        }

        setProductList([...response.productList])
    }

    // reset all new product modal states
    const resetProductModal = () => {
        setProductModalVisible(false)
        setConfirmLoading(false)
        setLoading(false)
        productDataForm.resetFields();
    }

    // reset all edit product modal states
    const resetEditDataModal = () => {
        setProductEditModal(false);
        setConfirmLoading(false);
        setLoading(false);
        setEditItemID("");
        editDataForm.resetFields();
    }

    // Create new product and add in product list
    const handleProductSubmit = async (values) => {
        setConfirmLoading(true)
        const { product, productDescription } = values;
        let emailBody = await generateEmailBody(product, productDescription, UserId);

        if (emailBody === undefined) {
            emailBody = "";
        }

        let params = {
            firebaseAuthUuid: UserId,
            product: product,
            productDescription: productDescription,
            emailBody: emailBody
        }

        try {
            await axios.post(`${serverURL}/userProductData`, params)
        } catch (error) {
            console.log(error);
        }

        await fetchProductList()
        resetProductModal()
    }

    // Open edit modal and auto fill selected row values
    const handleEditModalAndAutoFill = async (record) => {
        setProductEditModal(true)
        setEditItemID(record.productId);

        editDataForm.setFieldValue("product", record.product);
        editDataForm.setFieldValue("productDescription", record.productDescription);
        editDataForm.setFieldValue("default", record.default);
        editDataForm.setFieldValue("emailBody", record.emailBody);
    }

    // 
    const saveProductEdit = async (record) => {
        setConfirmLoading(true)

        let params = {
            firebaseAuthUuid: UserId,
            productId: editItemId,
            product: record.product,
            productDescription: record.productDescription,
            emailBody: record.emailBody
        }

        try {
            await axios.post(`${serverURL}/updateUserProductData`, params)
                .then((res) => {
                    if (res.data === "Product updated") {
                        messageApi.open({
                            type: 'success',
                            content: 'Product Updated',
                        });
                    }
                })
        } catch (error) {
            console.log(error);
        }

        await fetchProductList()
        resetEditDataModal()
    }

    // Update default product
    const updateDefaultProduct = async (productId) => {
        setLoading(true);

        let params = {
            firebaseAuthUuid: UserId,
            productId: productId
        }

        try {
            await axios.patch(`${serverURL}/userProductData`, params)
        } catch (error) {
            console.log(error);
        }

        setLoading(false);
    }

    // delete product from user's product list
    const deleteProduct = async (id) => {
        setLoading(true)

        let response;
        try {
            await axios.get(`${process.env.REACT_APP_SERVER_URL}/userProductData/${UserId}`)
                .then((res) => {
                    response = res.data.defaultProductID;
                })
        } catch (error) {
            console.log(error);
        }

        if (response === id) {
            messageApi.open({
                type: 'error',
                content: "Can't delete default product",
            });

            setLoading(false)
            return;
        }

        try {
            await axios.delete(`${serverURL}/userProductData/?userId=${UserId}&productId=${id}`)
                .then((res) => {
                    if (res.data === "Deleted") {
                        messageApi.open({
                            type: 'success',
                            content: 'Product Deleted',
                        });
                    }
                })
        } catch (error) {
            console.log(error);
        }

        setLoading(false)
    }

    const prepareExportData = async () => {
        let rawData = userHistory[exportYear]?.[exportMonth];
        let rows = [];

        if (rawData !== undefined) {
            for (const day in rawData) {
                rawData[day].forEach(item => {
                    const { task, credit, timestamp, ai_credits_left, contact_credits_left } = item;
                    const date = new Date(timestamp).toLocaleDateString(); // Format the date as desired
                    const time = new Date(timestamp).toLocaleTimeString(); // Format the time as desired
                    const row = [date, time, task, credit, ai_credits_left, contact_credits_left];
                    rows.push(row);
                });
            }
        }

        setExportData(rows);
    }

    useEffect(() => {
        if (UserId !== "") {
            fetchProductList()
        }
    }, [UserId]);

    useEffect(() => {
        prepareExportData()
    }, [exportYear, exportMonth]);

    return loggedInUser ? (
        <div className={styles.container}>
            {/* contextHolder is Required for Success, Warning and Error messages */}
            {contextHolder}

            {/* Add Product Modal */}
            <Modal
                title=""
                visible={productModalVisible}
                onOk={() => {
                    setProductModalVisible(false);
                }}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    resetProductModal()
                }}
                footer={null}
                centered={true}
            >
                <Form
                    form={productDataForm}
                    name="basic"
                    layout="vertical"
                    style={{
                        margin: "30px 0px 10px 0px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                    labelCol={{
                        span: 30,
                    }}
                    wrapperCol={{
                        span: 100,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={async (values) => {
                        await handleProductSubmit(values);
                    }}
                    autoComplete="off"
                >

                    <Form.Item
                        style={{ width: "90%", paddingLeft: "10%" }}
                        name="product"
                        label="Product Name"
                        rules={[
                            {
                                required: true,
                                message: "Product name is required",
                            },
                        ]}
                    >
                        <Input placeholder="eg: Microsoft" />
                    </Form.Item>

                    <Form.Item
                        style={{ width: "90%", paddingLeft: "10%" }}
                        name="productDescription"
                        label="Product Description"
                        rules={[
                            {
                                required: true,
                                message: "Product description is required",
                            },
                        ]}
                    >
                        <Input placeholder="eg: Microsoft is an American multinational technology corporation." />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 20,
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={confirmLoading}>
                            Add Product Data
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Product Edit Modal */}
            <Modal
                title=""
                visible={productEditModal}
                onOk={() => {
                    setProductEditModal(false);
                }}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    resetEditDataModal()
                }}
                footer={null}
                centered={true}
            >
                <Form
                    form={editDataForm}
                    name="basic"
                    layout="vertical"
                    style={{
                        margin: "30px 0px 10px 0px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                    labelCol={{
                        span: 30,
                    }}
                    wrapperCol={{
                        span: 100,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={async (values) => {
                        await saveProductEdit(values);
                    }}
                    autoComplete="off"
                >

                    <Form.Item
                        style={{ width: "90%", paddingLeft: "10%" }}
                        name="product"
                        label="Product Name"
                        rules={[
                            {
                                required: true,
                                message: "Product name is required",
                            },
                        ]}
                    >
                        <Input placeholder="eg: Microsoft" />
                    </Form.Item>

                    <Form.Item
                        style={{ width: "90%", paddingLeft: "10%" }}
                        name="productDescription"
                        label="Product Description"
                        rules={[
                            {
                                required: true,
                                message: "Product description is required",
                            },
                        ]}
                    >
                        <Input placeholder="eg: Microsoft is an American multinational technology corporation." />
                    </Form.Item>

                    <Form.Item
                        style={{ width: "90%", paddingLeft: "10%" }}
                        name="emailBody"
                        label="Email Body"
                        rules={[
                            {
                                required: true,
                                message: "Email Body is required",
                            },
                        ]}
                    >
                        <Input placeholder="eg: Microsoft is an American multinational technology corporation." />
                    </Form.Item>

                    <Form.Item
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <Button type="primary" htmlType="submit" loading={confirmLoading}>
                            Update Product Data
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>


            {/* Top Header/Navbar */}
            <Topnav />


            <div className={styles.contentWrapper}>
                {/* Options type Section */}
                <div className={styles.sidebar}>
                    <span className={styles.optionsLabelContainer} onClick={() => handleOptionChange("product")}>
                        <CubeIcon className={styles.labelIcon} color={"#4659ff"} />
                        <h1 className={styles.labelType}>Product</h1>
                    </span>
                    <span className={styles.optionsLabelContainer} onClick={() => handleOptionChange("transactions")}>
                        <CurrencyDollarIcon className={styles.labelIcon} color={"#4659ff"} />
                        <h1 className={styles.labelType}>Transactions</h1>
                    </span>
                    <Divider />
                </div>

                {/* Options options based on selected type */}
                <div className={styles.content}>
                    {
                        optionsType === "product" &&
                        <div className="enrich-table-card">
                            <div className={styles.productHeader}>
                                <button
                                    className="primary-button"
                                    style={{ margin: "0px 5px" }}
                                    onClick={async (e) => {
                                        setLoading(true);
                                        setProductModalVisible(true)
                                    }}
                                >
                                    Add Product <PlusOutlined />
                                </button>
                            </div>
                            <Table
                                headers={false}
                                style={{ padding: "0px 30px" }}
                                size="large"
                                columns={productListTableColumn}
                                loading={{
                                    indicator: (
                                        <LoadingOutlined
                                            style={{
                                                fontSize: 40,
                                                fontWeight: 600,
                                            }}
                                        />
                                    ),
                                    spinning: loading,
                                }}
                                rowKey="index"
                                dataSource={[...productList]}
                                pagination={true}
                                scroll={{ y: "max-content" }}
                            />
                        </div>
                    }
                    {
                        optionsType === "transactions" &&
                        <div className={styles.transactionPageWrapper}>
                            <Select
                                defaultValue="Select Year"
                                onChange={(value) => setExportYear(value)}
                                style={{ width: "150px" }}
                            >
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </Select>
                            <Select
                                defaultValue="Select Month"
                                onChange={(value) => setExportMonth(value)}
                                style={{ width: "150px" }}
                            >
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">Octomber</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </Select>

                            {
                                exportMonth && exportYear && exportData.length > 0 ?
                                    <CSVLink
                                        data={exportData}
                                        headers={exportHeaders}
                                        filename={"credit-history.csv"}
                                        className={styles.primaryButton}
                                        target="_blank"
                                        style={{ width: "150px" }}

                                    >
                                        Export CSV
                                    </CSVLink>
                                    :
                                    <Button className={styles.primaryButton} disabled={true} style={{ width: "150px" }}>
                                        Export CSV
                                    </Button>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    ) : (
        <div
            style={{
                height: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin
                indicator={
                    <LoadingOutlined
                        style={{
                            fontSize: 60,
                            fontWeight: 600,
                        }}
                        spin
                    />
                }
            />
        </div>
    );
}

export { Options }