/**
 * People contact search tab
 */

import React, { useEffect, useState, useContext } from "react";
import {
  db,
  auth,
  fetchCompanyList,
  saveCompany,
  getUserSavedIntegrationSettings,
  performInBulk,
  getAccountType,
  getProductInfo,
} from "../../firebase-config";
import { arrayRemove, doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import {
  useNavigate,
  Link,
  useSearchParams,
  useParams,
} from "react-router-dom";
import {
  Button,
  Table,
  Select,
  Drawer,
  Space,
  Divider,
  Spin,
  Tree,
  Tag,
  Tabs,
  Popover,
  Cascader,
  notification,
  Pagination,
  Modal,
  Typography,
  message
} from "antd";

import axios from "axios";
import {
  UserGroupIcon,
  LinkIcon,
  OfficeBuildingIcon,
  LibraryIcon,
  TrendingUpIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  StarIcon,
  UsersIcon,
  ServerIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { CSVLink } from "react-csv";

import salesforceIcon from "../../Assets/integration-icons/salesforce-icon.svg";
import hubspotIcon from "../../Assets/integration-icons/hubspot-icon.svg";

import {
  industryCascaderOptions,
  country_list,
  isValid,
} from "../../Utils/list.js";
import {
  FacebookFilled,
  LinkedinFilled,
  TwitterSquareFilled,
  LoadingOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { UserIdContext } from "../../Context/UserIdContext";
import { CreditCountContext } from "../../Context/CreditCountContext";
import { PeopleSearchQueryContext } from "../../Context/PeopleSearchQueryContext";
import CustomPagination from "../CustomPagination/CustomPagination";

export default function PeopleSearch({ credits }) {
  // console.log("props credits...", credits);

  const [selectedOrgchartNodeKeys, setSelectedOrgchartNodeKeys] = useState([]);

  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  const onOrgchartNodeSelect = (selectedKeysValue, info) => {
    console.log("onSelect", info);
    setSelectedOrgchartNodeKeys(selectedKeysValue);
  };

  //filter selector values
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedCompanySize, setSelectedCompanySize] = useState([]);
  const [selectedCompanyRevenue, setSelectedCompanyRevenue] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedFirstName, setSelectedFirstName] = useState([]);
  const [selectedLastName, setSelectedLastName] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedZipCode, setSelectedZipCode] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);

  //autocomplete options
  const [fetchedDomainOption, setFetchedDomainOption] = useState([]);

  //filter setting states
  const [titleExactMatch, setTitleExactMatch] = useState(false);
  const [cursorMark, setCursorMark] = useState("");
  const [resultLimit, setResultLimit] = useState(15);
  const [totalContactsAvailable, setTotalContactsAvailable] = useState("");

  //misc states
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState([]);

  const [savedCompanyList, setSavedCompanyList] = useState([]);
  const [isSaveCompanyPopoverVisible, setIsSaveCompanyPopoverVisible] =
    useState(false);

  //pagination
  const [paginationData, setPaginationData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const [loading, setLoading] = useState(false);
  const [orgChartDrawerVisible, setOrgChartDrawerVisible] = useState(false);
  const [firebaseAuthUUID, setFirebaseAuthUUID] = useState("");

  //context
  const [UserId, setUserId] = useContext(UserIdContext);
  const { CreditCount, setCreditCount, AICreditCount, setAICreditCount } = useContext(CreditCountContext);
  const [PeopleSearchQuery, setPeopleSearchQuery] = useContext(
    PeopleSearchQueryContext
  );
  const [accountType, setAccountType] = useState("");

  // const [accessToken, setAccessToken] = useState("");
  const [savedIntegrationSettings, setSavedIntegrationSettings] = useState({});
  const [selectedSaveContactDestination, setSelectedSaveContactDestination] =
    useState("salesforce");

  const { Option } = Select;
  const { Paragraph } = Typography;
  var { search_type } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fullEmailModal, setFullEmailModal] = useState(false);
  const [fullEmail, setFullEmail] = useState({});
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const [copyLoading, setCopyLoading] = useState(false);
  const [regenerateButtonText, setRegenerateButtonText] = useState("Regenerate");
  const [emailRewriteLoading, setEmailRewriteLoading] = useState(false);
  const [selectedRecordData, setSelectedRecordData] = useState({})

  var selectedCompanyList = "";
  //const serverURL = "http://localhost:6060";
  // const serverURL = "https://leadzilla.herokuapp.com";
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const clearbitAutocompleteAPIUrl =
    "https://autocomplete.clearbit.com/v1/companies/suggest";

  var credits = CreditCount;

  // useEffect(() => {
  //   console.log("params", search_type);
  // }, [search_type]);

  // get filters from url use it to fetch latest contact data
  useEffect(() => {
    try {
      let filterFromUrl = JSON.parse(searchParams.get("filter"));
      // console.log("search query from URL", filterFromUrl);

      // if filter in url preset use it as search query
      if (
        filterFromUrl !== undefined &&
        filterFromUrl !== null &&
        filterFromUrl !== " "
      ) {
        setSelectedDomain(filterFromUrl.domain || []);
        setSelectedLevel(filterFromUrl.level || []);
      }
      // otherwise fetch results for prev search query saved in context
      else {
        // console.log("previous search query from context");
        // console.log(PeopleSearchQuery);
        //set filters to last saved seach query
        setSelectedCompany(PeopleSearchQuery.companyName);
        setSelectedDomain(PeopleSearchQuery.companyDomain);
        setSelectedIndustry(PeopleSearchQuery.industry);
        setSelectedCompanySize(PeopleSearchQuery.headcount);
        setSelectedCompanyRevenue(PeopleSearchQuery.revenue);
        setSelectedTitle(PeopleSearchQuery.title);
        setSelectedLevel(PeopleSearchQuery.seniority);
        setSelectedDepartment(PeopleSearchQuery.department);
        setSelectedName(PeopleSearchQuery.fullName);
        setSelectedCountry(PeopleSearchQuery.country);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  // used when saving a company
  useEffect(() => {
    fetchSavedListNames();
  }, [UserId]);

  //
  // get users saved integrations setting on pageload
  useEffect(() => {
    getUserSavedIntegrationData();
  }, [UserId]);

  useEffect(() => {
    (async () => {
      if (isValid(UserId)) {
        let type = await getAccountType(UserId);
        setAccountType(type);
      }
    })()
  }, [UserId]);

  //changes data to render by slicing all fetched data
  // useEffect(() => {
  //   let dataToDisplay = paginationData.slice(
  //     pageNumber * resultLimit,
  //     pageNumber * resultLimit + resultLimit
  //   );

  //   console.log("sliced paginated data", dataToDisplay);
  //   setSearchResult(dataToDisplay);
  // }, [pageNumber, resultLimit, paginationData]);

  //notification component
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const getUserSavedIntegrationData = async () => {
    let integrationSettings = await getUserSavedIntegrationSettings(UserId);
    // console.log("integration data", integrationSettings);
    setSavedIntegrationSettings(integrationSettings);
  };

  const fetchSavedListNames = async () => {
    try {
      // console.log("fetching saved list name");
      const list = await fetchCompanyList(UserId);
      setSavedCompanyList(list);
    } catch (err) {
      console.log(err);
    }
  };

  // saved company list options for selector
  const companyListOptions = [];
  savedCompanyList.forEach((country) => {
    companyListOptions.push(
      <Option key={country.listName}>{country.listName}</Option>
    );
  });

  function handleSaveCompanySelectChange(value) {
    console.log(value);
    selectedCompanyList = value;
  }

  const testDataSource = [
    {
      key: "1",
      name: "",
      level: "C Level",
      count: "3",
      child: false,
      children: [
        {
          key: "mflmbvkj",
          name: "Vikas",
          role: "CMO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
        {
          key: "wqguygdb",
          name: "Maruti",
          role: "CEO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
        {
          key: "sanmnfkjfk",
          name: "Naina",
          role: "CTO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
      ],
    },
    {
      key: "2",
      name: "",
      level: "Director Level",
      count: "0",
      child: false,
    },
    {
      key: "3",
      name: "",
      level: "VP Level",
      count: "0",
      child: false,
    },
    {
      key: "4",
      name: "",
      level: "Manager Level",
      count: "3",
      child: false,
      children: [
        {
          key: "mflmbvkj",
          name: "Vikas",
          role: "CMO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
        {
          key: "wqguygdb",
          name: "Maruti",
          role: "CEO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
        {
          key: "sanmnfkjfk",
          name: "Naina",
          role: "CTO",
          level: "",
          linkedInId: "www.linkedin.com/in/satyanadella/",
          child: true,
        },
      ],
    },
    {
      key: "5",
      name: "",
      level: "Staff Level",
      count: "0",
      child: false,
    },
    {
      key: "6",
      name: "",
      level: "Others",
      count: "0",
      child: false,
    },
  ];

  const testColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render(text, record) {
        return {
          props: {
            style: {
              display: "flex",
              flexDirection: "row",
              justifyContent: "flexStart",
              alignItems: "flexStart",
              padding: "5px 15px",
              margin: "5px 5px",
              marginLeft: record.child ? "70px" : "5px",
              border: "1px solid #e2e2e2",
              borderRadius: "5px",
              minHeight: "50px",
              minWidth: "200px",
              lineHeight: "5px",
              boxShadow: "0px 0px 10px 5px rgba(200, 200, 200, 0.2)",
            },
          },
          children: (
            <div
              style={{
                padding: "5px 15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flexStart",
                alignItems: "flexStart",
              }}
            >
              <p>{record.name}</p>
              {record.level ? <p>{record.level}</p> : ""}
              <p>{record.count}</p>
              <p>{record.role}</p>

              {record.linkedInId ? (
                <a
                  href={"http://" + record.linkedInId}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  LinkedIn
                </a>
              ) : (
                ""
              )}
            </div>
          ),
        };
      },
    },
  ];

  const [CSVHeaders] = useState([
    { label: "fullName", key: "fullName" },
    { label: "firstName", key: "firstName" },
    { label: "lastName", key: "lastName" },
    { label: "companyName", key: "companyName" },
    { label: "website", key: "primaryDomain" },
    { label: "jobTitle", key: "title" },
    { label: "emailId", key: "emailAddress" },
    { label: "phoneNumber", key: "phoneNumber" },
    { label: "phoneNumbers", key: "phone" },
    { label: "phoneDirect", key: "phoneDirect" },
    { label: "phoneCompany", key: "phoneCompany" },
    { label: "phoneMobile", key: "phoneMobile" },
    { label: "uniqueLinkedinId", key: "linkedInId" },
    { label: "twitterId", key: "twitterId" },
    { label: "facebookId", key: "facebookId" },
  ]);

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h4 className="contact-name">
            {record.firstName + " " + record.lastName}
          </h4>
          <p className="contact-role">{record.title}</p>

          <div>
            {record.dept?.map((department, index) => {
              return (
                <p className="contact-department" key={index}>
                  {department}
                </p>
              );
            })}
          </div>

          <span className="social-account-link-container">
            {record.linkedInId ? (
              <a
                href={record.linkedInId}
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkedinFilled className="social-icons" color={"#4659ff"} />
              </a>
            ) : (
              ""
            )}
            {record.facebookId ? (
              <a
                href={"http://" + record.facebookId}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookFilled className="social-icons" color={"#4659ff"} />
              </a>
            ) : (
              ""
            )}
            {record.twitterId ? (
              <a
                href={"http://" + record.twitterId}
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterSquareFilled
                  className="social-icons"
                  color={"#4659ff"}
                />
              </a>
            ) : (
              ""
            )}
          </span>
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      render: (text, record, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/**
            * Use clearbit api to fetch company logos using domain name
            */}
          <img
            style={{
              maxWidth: "30px",
              background: "#f6f6f6",
              marginBottom: "5px",
            }}
            src={
              "https://logo.clearbit.com/" + record.primaryDomain + "?size=30"
            }
            alt=""
          />

          {record.companyName ? (
            <Link to={`/company?domain=${record.primaryDomain}`}>
              <h1 className="company-name">{record.companyName}</h1>
            </Link>
          ) : (
            <Link to={`/company?domain=${record.primaryDomain}`}>
              <h1 className="company-name">
                {record.primaryDomain?.split(".")[0]}
              </h1>
            </Link>
          )}

          {record.city || record.country ? (
            <div className="company-address">
              <p className="company-details">
                {record.city}, {" " + record.country}
              </p>
            </div>
          ) : (
            ""
          )}

          <div>
            {record.subIndustry?.map((subInd, index) => {
              return (
                <p className="contact-department" key={index}>
                  {subInd}
                </p>
              );
            })}
          </div>
          <p className="company-details">{record.industry}</p>

          {record.companySize ? (
            <p className="company-details">{record.companySize} employees</p>
          ) : (
            ""
          )}

          {record.companyRevenue ? (
            <p className="company-details">{record.companyRevenue} revenue</p>
          ) : (
            ""
          )}

          <a
            href={"http://" + record.primaryDomain}
            rel="noopener noreferrer"
            target="_blank"
            className="company-url"
          >
            {record.primaryDomain}
          </a>
        </div>
      ),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text, record, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>

          <p className="contact-email">
            {record.emailAddress ||  (isValid(record.primaryDomain) ? "***@" + record.primaryDomain : "")}
          </p>
          {record.phoneDirect && record.phoneDirect !== "" ? (
            <div className="contact-container">
              <p className="contact-type">Direct</p>
              <p className="contact-data">{record.phoneDirect}</p>
            </div>
          ) : (
            ""
          )}
          {record.phoneCompany && record.phoneCompany !== "" ? (
            <div className="contact-container">
              <p className="contact-type">Company</p>
              <p className="contact-data">{record.phoneCompany}</p>
            </div>
          ) : (
            ""
          )}
          {record.phoneMobile && record.phoneMobile !== "" ? (
            <div className="contact-container">
              <p className="contact-type">Mobile</p>
              <p className="contact-data">{record.phoneMobile}</p>
            </div>
          ) : (
            ""
          )}

          {record.phoneNumber && record.phoneNumber !== "" ? (
            <div className="contact-container">
              <p className="contact-type">Other</p>
              <p className="contact-data">{record.phoneNumber}</p>
            </div>
          ) : (
            ""
          )}

          {record?.phone?.length > 0 ? (
            <div className="contact-container">
              <p className="contact-type">Phone</p>
              {
                record.phone.map((phone, index) => {
                  return (
                    <p className="contact-data" key={index}>{phone}</p>
                  )
                })
              }
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <>
          <button
            style={{ margin: "2px 5px 2px 0px" }}
            className="secondary-button-active"
            onClick={async (e) => {

              let params = {
                contactId: record.id,
                firebaseId: UserId,
                firstName: record.firstName,
                lastName: record.lastName,
                companyName: record.companyName,
                domain: record.primaryDomain,
                country: record.country,
                linkedInProfileUrl: record.linkedInId,
              }

              setLoading(true);
              await purchaseContact(index, params, "single");
              setLoading(false);
            }}
          >
            View
          </button>

          {/** save contact to crms */}
          <Popover
            title={"Save contact to"}
            placement="rightTop"
            trigger="hover"
            content={
              <>
                <Select
                  bordered={true}
                  allowClear
                  defaultValue={"salesforce"}
                  value={selectedSaveContactDestination}
                  placeholder="Select a destination"
                  onChange={(selectedOption) => {
                    //console.log(selectedOption);
                    setSelectedSaveContactDestination(selectedOption);
                  }}
                  style={{ width: "100%", margin: "5px 10px 10px 5px" }}
                >
                  <Option key="salesforce" value="salesforce">
                    {
                      <span
                        style={{ display: "flex", margin: "0px 0px 0px 0px" }}
                      >
                        <img
                          src={salesforceIcon}
                          alt=""
                          style={{ height: "30px", marginRight: "10px" }}
                        />
                        <h3>Salesforce</h3>
                      </span>
                    }
                  </Option>
                  <Option key="hubspot" value="hubspot">
                    {
                      <span
                        style={{ display: "flex", margin: "0px 0px 0px 0px" }}
                      >
                        <img
                          src={hubspotIcon}
                          alt=""
                          style={{ height: "30px", marginRight: "10px" }}
                        />
                        <h3>Hubspot</h3>
                      </span>
                    }
                  </Option>
                </Select>

                <Button
                  style={{ margin: "10px 2px 5px 5px" }}
                  // className="secondary-button-active"
                  onClick={async (e) => {
                    // purchaseContact(index, record.id);
                    await saveContactToThirdPartyApps(record);
                    setIsSaveCompanyPopoverVisible(false);
                  }}
                >
                  Save
                </Button>
              </>
            }
          >
            <button
              style={{ margin: "2px 5px 2px 0px" }}
              className="secondary-button-active"
              onClick={(e) => {
                //purchaseContact(index, record.id);
                setIsSaveCompanyPopoverVisible(true);
              }}
            >
              Save
            </button>
          </Popover>

          <div>
            <button
              style={{ margin: "2px 5px 2px 0px" }}
              className="secondary-button-active"
              onClick={(e) => {
                //purchaseContact(index, record.id);
                handleModalAndEmailWriting(record);
              }}
            >
              Write mail
            </button>
          </div>

          {/** save company to list */}
          <Popover
            title={"Save to track list"}
            placement="rightTop"
            trigger="hover"
            content={
              <>
                <Select
                  bordered={true}
                  allowClear
                  placeholder="Select a company list"
                  onChange={handleSaveCompanySelectChange}
                  style={{ width: "100%", margin: "5px 10px 10px 5px" }}
                >
                  {companyListOptions}
                </Select>

                <Button
                  style={{ margin: "2px 5px" }}
                  // className="secondary-button-active"
                  onClick={async (e) => {
                    // purchaseContact(index, record.id);
                    const isCompanySaved = await saveCompany(
                      UserId,
                      selectedCompanyList,
                      record.primaryDomain,
                      record.primaryDomain?.split(".")[0]
                    );

                    if (isCompanySaved === true) {
                      openNotificationWithIcon(
                        "success",
                        "Saved",
                        "Company saved for tracking"
                      );
                    } else {
                      openNotificationWithIcon(
                        "error",
                        "Not saved",
                        "Could not save this company for tracking, contact support"
                      );
                    }

                    setIsSaveCompanyPopoverVisible(false);
                  }}
                >
                  Save
                </Button>
              </>
            }
          >
            <button
              className="primary-button"
              style={{ marginRight: "0px", padding: "0px 20px" }}
              onClick={() => {
                setIsSaveCompanyPopoverVisible(true);
              }}
            >
              Track company
            </button>
          </Popover>

          {/* <button
               style={{ margin: "2px 5px" }}
               className="secondary-button-active"
               onClick={(e) => {
                 showOrgChart(
                   record.level,
                   record.companyName,
                   record.primaryDomain
                 );
                 console.log("button-clicked");
               }}
             >
               Org Chart
             </button> */}
        </>
      ),
    },
  ];

  //User data row selection logic
  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
      // save selected user data rows
      setSelectedUserData(selectedRows);
    },
    // onSelect: (record, selected, selectedRows) => {
    //   console.log(record, selected, selectedRows);
    // },
    // onSelectAll: (selected, selectedRows, changeRows) => {
    //   console.log(selected, selectedRows, changeRows);
    // },
  };

  const countryOptions = [];
  country_list.forEach((country) => {
    countryOptions.push(<Option key={country.Name}>{country.Name}</Option>);
  });

  function customSelectTagUI(props) {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"green"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          fontWeight: "500",
          color: "green",
          margin: "3px 3px 3px 0px",
        }}
      >
        {label}
      </Tag>
    );
  }

  const departmentOptions = [
    <Option key={"Engineering"}>{"Engineering"}</Option>,
    <Option key={"Finance & Administration"}>
      {"Finance & Administration"}
    </Option>,
    <Option key={"Human Resources"}>{"Human Resources"}</Option>,
    <Option key={"IT & IS"}>{"IT & IS"}</Option>,
    <Option key={"Marketing"}>{"Marketing"}</Option>,
    <Option key={"Operations"}>{"Operations"}</Option>,
    <Option key={"Sales"}>{"Sales"}</Option>,
    <Option key={"Support"}>{"Support"}</Option>,
    <Option key={"Other"}>{"Other"}</Option>,
  ];

  const levelOptions = [
    <Option key={"C-Level"}>{"C-Level"}</Option>,
    <Option key={"VP-Level"}>{"VP-Level"}</Option>,
    <Option key={"Director-Level"}>{"Director-Level"}</Option>,
    <Option key={"Manager-Level"}>{"Manager-Level"}</Option>,
    <Option key={"Staff"}>{"Staff"}</Option>,
    <Option key={"Other"}>{"Other"}</Option>,
  ];

  const industryOptions = [
    <Option key={"Agriculture & Mining"}>{"Agriculture & Mining"}</Option>,
    <Option key={"Business Services"}>{"Business Services"}</Option>,
    <Option key={"Computers & Electronics"}>
      {"Computers & Electronics"}
    </Option>,
    <Option key={"Consumer Services"}>{"Consumer Services"}</Option>,
    <Option key={"Education"}>{"Education"}</Option>,
    <Option key={"Energy & Utilities"}>{"Energy & Utilities"}</Option>,
    <Option key={"Financial Services"}>{"Financial Services"}</Option>,
    <Option key={"Government"}>{"Government"}</Option>,
    <Option key={"Healthcare, Pharmaceuticals, & Biotech"}>
      {"Healthcare, Pharmaceuticals, & Biotech"}
    </Option>,
    <Option key={"Manufacturing"}>{"Manufacturing"}</Option>,
    <Option key={"Media & Entertainment"}>{"Media & Entertainment"}</Option>,
    <Option key={"Non-Profit"}>{"Non-Profit"}</Option>,
    <Option key={"Other"}>{"Other"}</Option>,
    <Option key={"Real Estate & Construction"}>
      {"Real Estate & Construction"}
    </Option>,
    <Option key={"Retail"}>{"Retail"}</Option>,
    <Option key={"Software & Internet"}>{"Software & Internet"}</Option>,
    <Option key={"Telecommunications"}>{"Telecommunications"}</Option>,
    <Option key={"Transportation & Storage"}>
      {"Transportation & Storage"}
    </Option>,
    <Option key={"Travel, Recreation, and Leisure"}>
      {"Travel, Recreation, and Leisure"}
    </Option>,
    <Option key={"Wholesale & Distribution"}>
      {"Wholesale & Distribution"}
    </Option>,
  ];

  const companySizeOptions = [
    <Option key={"0 - 25"}>{"0 - 25"}</Option>,
    <Option key={"25 - 100"}>{"25 - 100"}</Option>,
    <Option key={"100 - 250"}>{"100 - 250"}</Option>,
    <Option key={"250 - 1000"}>{"250 - 1000"}</Option>,
    <Option key={"1k - 10k"}>{"1k - 10k"}</Option>,
    <Option key={"10k - 50k"}>{"10k - 50k"}</Option>,
    <Option key={"50k - 100k"}>{"50k - 100k"}</Option>,
    <Option key={"> 100k"}>{"> 100k"}</Option>,
  ];

  const companyRevenueOptions = [
    <Option key={"$0 - 1M"}>{"$0 - 1M"}</Option>,
    <Option key={"$1 - 10M"}>{"$1 - 10M"}</Option>,
    <Option key={"$10 - 50M"}>{"$10 - 50M"}</Option>,
    <Option key={"$50 - 100M"}>{"$50 - 100M"}</Option>,
    <Option key={"$100 - 250M"}>{"$100 - 250M"}</Option>,
    <Option key={"$250 - 500M"}>{"$250 - 500M"}</Option>,
    <Option key={"$500M - 1B"}>{"$500M - 1B"}</Option>,
    <Option key={"> $1B"}>{"> $1B"}</Option>,
  ];

  const resultFetchLimitOptions = [
    <Option key={15}>15</Option>,
    <Option key={25}>25</Option>,
    <Option key={50}>50</Option>,
    <Option key={75}>75</Option>,
    <Option key={100}>100</Option>,
  ];

  const fetchSearchResult = async (
    selectedDepartment,
    selectedLevel,
    selectedIndustry,
    selectedCompany,
    selectedCompanySize,
    selectedCompanyRevenue,
    selectedName,
    selectedFirstName,
    selectedLastName,
    selectedTitle,
    selectedDomain,
    selectedCountry,
    titleExactMatch,
    cursorMark,
    resultLimit,
    fetchNext
  ) => {
    //seperate main industry & sub-indutry from combined industry
    let mainIndustry = [],
      subIndustry = [];
    selectedIndustry.forEach((filter) => {
      console.log(filter);
      if (filter.length > 1) {
        subIndustry.push(filter[1]);
      } else {
        mainIndustry.push(filter[0]);
      }
    });

    //filter payload
    var data = {
      name: selectedName,
      firstName: selectedFirstName,
      lastName: selectedLastName,
      title: selectedTitle,
      dept: selectedDepartment,
      level: selectedLevel,
      companyName: selectedCompany,
      nameDomain: selectedDomain,
      numberOfEmployees: selectedCompanySize,
      revenue: selectedCompanyRevenue,
      industryName: mainIndustry,
      subIndustry: subIndustry,
      city: [],
      country: selectedCountry,
      state: [],
      zipCode: [],
      dontDisplayDeadContacts: false,
      dontDisplayOwnedContacts: false,
      limit: resultLimit || 25,
      cursorMark: fetchNext === true ? cursorMark : "",
      titleExactMatch: titleExactMatch,
    };

    try {
      setLoading(true);
      await axios
        .post(`${serverURL}/contactsV2`, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            // Autherization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          let searchResultData = response.data.result;

          // add extra data points that are needed in exported data (eg: fullName)
          searchResultData?.forEach((data, index) => {
            data["fullName"] = data?.firstName + " " + data?.lastName;
          });

          // console.log("search result : ", searchResultData);
          //setSearchResult(searchResultData);

          let combinedData = [];
          if (fetchNext === true) {
            combinedData = [...paginationData, ...searchResultData];
          } else {
            combinedData = [...searchResultData];
          }

          // console.log("new pagination data", combinedData);

          setPaginationData(combinedData);
          setSelectedUserData([]);
          setCursorMark(response.data.cursorMark);
          setTotalContactsAvailable(response.data.hits);
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Purchase contact details for given contact. refer adapt.io purchase contact APIs
   * @param {index of element in data array} tableElementId
   * @param {adapt.io contact id used for purchasing that contact} contactId
   */
  const purchaseContact = async (tableElementId, params, clickType) => {
    try {

      let {
        contactId,
        firstName,
        lastName,
        companyName,
        firebaseId,
        linkedInProfileUrl,
        domain,
        country
      } = params;

      if (credits > 0) {
        await axios
          .get(`${serverURL}/purchaseV3?contactId=${contactId}&firebaseId=${firebaseId}&firstName=${firstName}&lastName=${lastName}&companyName=${companyName}&linkedInProfileUrl=${linkedInProfileUrl}&domain=${domain}&country=${country}`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Autherization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            // save purchased(found) email/phone data
            //let searchResultCopy = [...searchResult];

            let searchResultCopy = [
              ...paginationData.slice(
                pageNumber * resultLimit,
                pageNumber * resultLimit + resultLimit
              ),
            ];

            if (response.data.emailAddress) {
              searchResultCopy[tableElementId]["emailAddress"] =
                response.data.emailAddress;
            } else {
              searchResultCopy[tableElementId]["emailAddress"] = "";
            }

            if (response.data.emailStatus) {
              searchResultCopy[tableElementId]["emailStatus"] =
                response.data.emailStatus;
            } else {
              searchResultCopy[tableElementId]["emailStatus"] = "";
            }

            if (response.data.phoneDirect) {
              searchResultCopy[tableElementId]["phoneDirect"] =
                response.data.phoneDirect;
            } else {
              searchResultCopy[tableElementId]["phoneDirect"] = "";
            }

            if (response.data.phoneCompany) {
              searchResultCopy[tableElementId]["phoneCompany"] =
                response.data.phoneCompany;
            } else {
              searchResultCopy[tableElementId]["phoneCompany"] = "";
            }

            if (response.data.phoneMobile) {
              searchResultCopy[tableElementId]["phoneMobile"] =
                response.data.phoneMobile;
            } else {
              searchResultCopy[tableElementId]["phoneMobile"] = "";
            }

            if (response.data.phoneNumber) {
              searchResultCopy[tableElementId]["phoneNumber"] =
                response.data.phoneNumber;
            } else {
              searchResultCopy[tableElementId]["phoneNumber"] = "";
            }

            if (response.data.phoneNumbers) {
              searchResultCopy[tableElementId]["phone"] =
                response.data.phoneNumbers;
            } else {
              searchResultCopy[tableElementId]["phone"] = "";
            }

            //update changes in search result
            // replace this page with contact data with combined pagination data

            let startingIndexOfThisPage = pageNumber * resultLimit,
              entriesToReplace = searchResultCopy.length;

            let paginationDataCopy = [...paginationData];
            paginationDataCopy.splice(
              startingIndexOfThisPage,
              entriesToReplace,
              ...searchResultCopy
            );

            // console.log("copy", paginationDataCopy);
            setPaginationData(paginationDataCopy);
            //setSearchResult(searchResultCopy);

            return "success";
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (clickType === "single") {
          message.info("Don't have enough Contact credits");
        } else if (clickType === "multiple") {
          setLoading(false);
          return "failed";
        }
        console.log("credits not enough");
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Handle contact saving to third party apps
   */

  const saveContactToThirdPartyApps = async (record) => {
    console.log("selected desitination", selectedSaveContactDestination);

    if (selectedSaveContactDestination === "salesforce") {
      console.log("saving to salesforce....");

      let data = {};

      if (isValid(record.firstName)) {
        data["FirstName"] = record.firstName;
      }
      if (isValid(record.lastName)) {
        data["LastName"] = record.lastName;
      } else {
        data["LastName"] = "none";
      }
      if (isValid(record.companyName)) {
        data["Company"] = record.companyName;
      } else if (isValid(record.primaryDomain)) {
        data["Company"] = record.primaryDomain;
      }
      if (isValid(record.title)) {
        data["Title"] = record.title;
      }
      if (isValid(record.title)) {
        data["Title"] = record.title;
      }
      if (isValid(record.emailAddress)) {
        data["Email"] = record.emailAddress;
      } else if (isValid(record.primaryDomain)) {
        data["Email"] = isValid(record.primaryDomain) ? "***@" + record.primaryDomain : "";
      }
      if (isValid(record.phoneCompany) && isValid(record.phoneNumber)) {
        data["Phone"] = `${record.phoneCompany} ${record.phoneNumber}`;
      } else if (isValid(record.phoneCompany)) {
        data["Phone"] = `${record.phoneCompany} `;
      } else if (isValid(record.phoneNumber)) {
        data["Phone"] = `${record.phoneNumber} `;
      }

      if (isValid(record.phoneDirect) && isValid(record.phoneMobile)) {
        data["MobilePhone"] = `${record.phoneDirect} ${record.phoneMobile}`;
      } else if (isValid(record.phoneDirect)) {
        data["MobilePhone"] = `${record.phoneDirect} `;
      } else if (isValid(record.phoneMobile)) {
        data["MobilePhone"] = `${record.phoneMobile} `;
      }

      //other contact only exists for chrome extention

      if (isValid(record.industry)) {
        data["Industry"] = record.industry;
      }
      if (isValid(record.primaryDomain)) {
        data["Website"] = record.primaryDomain;
      }
      data["LeadSource"] = "Leadzilla";

      if (isValid(record.city)) {
        data["City"] = record.city;
      }
      if (isValid(record.state)) {
        data["State"] = record.state;
      }
      if (isValid(record.country)) {
        data["Country"] = record.country;
      }

      await saveLeadsOnSalesforce(data);
    } else if (selectedSaveContactDestination === "hubspot") {
      console.log("saving to hubspot....");
      let data = { properties: [] };

      //first name
      if (isValid(record.firstName)) {
        data.properties.push({
          property: "firstname",
          value: record.firstName,
        });
      }
      //last name
      if (isValid(record.lastName)) {
        data.properties.push({
          property: "lastname",
          value: record.lastName,
        });
      } else {
        data.properties.push({
          property: "lastname",
          value: "",
        });
      }

      //email
      if (isValid(record.emailAddress)) {
        data.properties.push({
          property: "email",
          value: record.emailAddress,
        });
      } else if (isValid(record.primaryDomain)) {
        data.properties.push({
          property: "email",
          value: isValid(record.primaryDomain) ? "***@" + record.primaryDomain : "",
        });
      }

      // conatct
      let combinedPhoneString = "";

      if (isValid(record.phoneCompany)) {
        combinedPhoneString = combinedPhoneString + record.phoneCompany + " ";
      }
      if (isValid(record.phoneNumber)) {
        combinedPhoneString = combinedPhoneString + record.phoneNumber + " ";
      }
      if (isValid(record.phoneDirect)) {
        combinedPhoneString = combinedPhoneString + record.phoneDirect + " ";
      }
      if (isValid(record.phoneMobile)) {
        combinedPhoneString = combinedPhoneString + record.phoneMobile + " ";
      }

      //other contact only exists for chrome extention
      data.properties.push({
        property: "phone",
        value: combinedPhoneString,
      });
      //title
      if (isValid(record.title)) {
        data.properties.push({
          property: "jobtitle",
          value: record.title,
        });
      }
      //company name
      if (isValid(record.companyName)) {
        data.properties.push({
          property: "company",
          value: record.companyName,
        });
      } else if (isValid(record.primaryDomain)) {
        data.properties.push({
          property: "company",
          value: record.primaryDomain,
        });
      }
      //industry
      if (isValid(record.industry)) {
        data.properties.push({
          property: "industry",
          value: record.industry,
        });
      }

      //website
      if (isValid(record.primaryDomain)) {
        data.properties.push({
          property: "website",
          value: record.primaryDomain,
        });
      }

      //city
      if (isValid(record.city)) {
        data.properties.push({
          property: "city",
          value: record.city,
        });
      }
      //state
      if (isValid(record.state)) {
        data.properties.push({
          property: "state",
          value: record.state,
        });
      }
      //company
      if (isValid(record.country)) {
        data.properties.push({
          property: "country",
          value: record.country,
        });
      }

      console.log("saving to hubspot", data);
      await saveContactOnHubspot(data);
    }
  };

  /**
   * Save leads to salesforce CRM
   */
  const saveLeadsOnSalesforce = async (leadData) => {
    try {
      let apiPayload = {
        auth_uuid: `${UserId}`,
        lead_data: leadData,
      };
      await axios
        .post(
          `${serverURL}/salesforce-save-leads`,
          JSON.stringify(apiPayload),
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Autherization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          let result = response.data;
          console.log("saleforce save leads", result);
          if (result[0] === "successful") {
            openNotificationWithIcon(
              "success",
              "Saved",
              "Contact saved successfully"
            );
          } else if (result[0] === "duplicate") {
            openNotificationWithIcon(
              "info",
              "Duplicate",
              "Contact is already saved"
            );
          } else if (result[0] === "unsuccessful") {
            openNotificationWithIcon(
              "error",
              "Not saved",
              "Could not save this contact check if you have configured integration"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Save contact to hubspot CRM
   */
  const saveContactOnHubspot = async (leadData) => {
    try {
      let apiPayload = {
        auth_uuid: `${UserId}`,
        lead_data: leadData,
      };

      //serverURL
      await axios
        .post(`${serverURL}/hubspot-save-leads`, JSON.stringify(apiPayload), {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            // Autherization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          let result = response.data;
          console.log("hubspot contact saved", result);
          if (result[0] === "successful") {
            openNotificationWithIcon(
              "success",
              "Saved",
              "Contact saved successfully"
            );
          } else if (result[0] === "duplicate") {
            openNotificationWithIcon(
              "info",
              "Duplicate",
              "Contact is already saved"
            );
          } else if (result[0] === "unsuccessful") {
            openNotificationWithIcon(
              "error",
              "Not saved",
              "Could not save this contact check if you have configured integration"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Save contact to Outreach as a prospect
   */
  const saveContactOnOutreach = async (leadData) => {
    try {
      let apiPayload = {
        auth_uuid: `${UserId}`,
        lead_data: leadData,
      };

      //serverURL
      await axios
        .post(`${serverURL}/outreach-save-leads`, JSON.stringify(apiPayload), {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            // Autherization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          let result = response.data;
          console.log("outreach contact saved", result);
          if (result[0] === "successful") {
            openNotificationWithIcon(
              "success",
              "Saved",
              "Contact saved successfully"
            );
          } else if (result[0] === "duplicate") {
            openNotificationWithIcon(
              "info",
              "Duplicate",
              "Contact is already saved"
            );
          } else if (result[0] === "unsuccessful") {
            openNotificationWithIcon(
              "error",
              "Not saved",
              "Could not save this contact check if you have configured integration"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // search for user's product based on firebase_auth_id/user_id
  const getProductName = async () => {
    try {
      const userDataRef = doc(db, "users", `${UserId}`);
      const docSnap = await getDoc(userDataRef);
      let productName = docSnap.data().product;
      return productName;
    } catch (err) {
      console.log(err)
    }
  }

  // deduct user's credit based on passed parameter
  const deductCredit = async (para) => {
    if (UserId && AICreditCount > 0) {
      const userDataRef = doc(db, "users", `${UserId}`);
      let newCredit = +AICreditCount - para;
      await updateDoc(userDataRef, {
        personalisationCredits: `${newCredit}`,
      });

      setAICreditCount(newCredit)
    }
  }

  // generate new mail with subject and body
  const generateEmail = async (user = selectedRecordData) => {
    setEmailRewriteLoading(true);
    setRegenerateButtonText("Writing...");

    if (AICreditCount <= 0) {
      setEmailRewriteLoading(false);
      setRegenerateButtonText("Regenerate");
      return false;
    }

    let tempFullEmail = {};

    let linkedInParams = {
      firstName: user.firstName,
      lastName: user.lastName,
      domain: user.primaryDomain,
    };

    let product;
    if(isValid(UserId)){
      product = await getProductInfo(UserId);
    }
    console.log("product", product);
    console.log("linkedIn ID", user.linkedInId);

    let mailParams = {
      linkedInUrl: user.linkedInId,
      firebaseAuthUuid: UserId,
    };

    // if linkedIn url don't exist, first find that before writing email
    if (user.linkedInId === null) {
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/findLinkedInURL`,
          linkedInParams
        )
        .then((res) => {
          mailParams.linkedInUrl = res.data.url;
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          return false;
        });
    }

    // Check if linkedIn url is available
    if (mailParams?.linkedInUrl?.includes("null") || mailParams?.linkedInUrl === "" || mailParams?.linkedInUrl === null || mailParams?.linkedInUrl === undefined) {
      console.log("LinkedIn URL not found, can't write mail for this prospect.");
      message.info("Can't write mail for this prospect.");
      setLoading(false);
      setEmailRewriteLoading(false);
      setRegenerateButtonText("Regenerate");
      return false;
    } else {
      console.log("LinkedIn URL found, writing mail for this prospect.");
    }

    
    // generate mail body based on linkedIn url 
    await axios
    .post(`${process.env.REACT_APP_SERVER_URL}/writeFullEmailV2`, mailParams)
    .then(async (res) => {
      let subject_line_string = res.data.email.trim().split("\n")[0];
      let subject_line = subject_line_string.split(":")[1].trim();
      let email_array = res.data.email.trim().split("\n");
      let email_body = email_array.slice(2, email_array.length).join("\n");
      
      // save email body and subject to tempFullEmail
      tempFullEmail.body = email_body;
      tempFullEmail.subject = subject_line;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return false;
      });

    setFullEmail(tempFullEmail)
    setEmailRewriteLoading(false);
    setRegenerateButtonText("Done!!");
    setTimeout(() => {
      setRegenerateButtonText("Regenerate");
    }, 1000);

    return true;
  }

  // async function for copying email subject and body to clipboard
  const copyTextToClipboard = async () => {
    setCopyLoading(true);
    setCopyButtonText("Copying...");

    // prepare copy text and copy to clipboard
    let copyText = `${fullEmail.subject} \n\n${fullEmail.body}`;
    await navigator.clipboard.writeText(copyText);

    setCopyLoading(false);
    setCopyButtonText("Copied!!");
    setTimeout(() => {
      setCopyButtonText("Copy");
    }, 1000);
  }

  // handle modal opening, loading on table, and email writing
  const handleModalAndEmailWriting = async (user) => {
    setLoading(true);
    // store record's data, it will be useful while regenerating email
    setSelectedRecordData({ ...user });

    if (AICreditCount <= 0) {
      setLoading(false);
      message.info("Don't have enough AI credits");
      console.log("No credits left");
      return;
    }

    // generate new email based on record data
    let res = await generateEmail(user);
    if (res === true) {
      setFullEmailModal(true);
    }

    setLoading(false);
  };

  const handleEmailEdit = (e) => {
    let value = e.target.value;
    let obj = { "subject": fullEmail.subject, "body": value };

    setFullEmail(obj)
  }

  //respond to changes on filter values
  useEffect(() => {
    let fetchNext = false;
    if (
      selectedDepartment.length > 0 ||
      selectedLevel.length > 0 ||
      selectedIndustry.length > 0 ||
      selectedCompanySize.length > 0 ||
      selectedCompanyRevenue.length > 0 ||
      selectedName.length > 0 ||
      selectedFirstName.length > 0 ||
      selectedLastName.length > 0 ||
      selectedTitle.length > 0 ||
      selectedDomain.length > 0 ||
      selectedCompany.length > 0 ||
      selectedCountry.length > 0
    ) {
      //clear pagination data to avoid unreliablity

      // on filter change set current page to page 0
      setPageNumber(0);

      fetchSearchResult(
        selectedDepartment,
        selectedLevel,
        selectedIndustry,
        selectedCompany,
        selectedCompanySize,
        selectedCompanyRevenue,
        selectedName,
        selectedFirstName,
        selectedLastName,
        selectedTitle,
        selectedDomain,
        selectedCountry,
        titleExactMatch,
        cursorMark,
        resultLimit,
        fetchNext
      );
      // console.log(selectedDepartment);
      // console.log(selectedLevel);
      // console.log(selectedIndustry);
      // console.log(selectedCompanySize);
      // console.log(selectedCompanyRevenue);
      // console.log(selectedName);
      // console.log(selectedFirstName);
      // console.log(selectedLastName);
      // console.log(selectedTitle);
      // console.log(selectedDomain);
      // console.log(selectedCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDepartment,
    selectedLevel,
    selectedIndustry,
    selectedCompanySize,
    selectedCompanyRevenue,
    selectedName,
    selectedFirstName,
    selectedLastName,
    selectedTitle,
    selectedDomain,
    selectedCompany,
    selectedCountry,
    titleExactMatch,
    resultLimit,
  ]);

  const [treeData, setTreeData] = useState([
    {
      level: "C-Level",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },
    {
      level: "VP-Level",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },
    {
      level: "Director-Level",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },

    {
      level: "Manager-Level",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },

    {
      level: "Staff",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },
    {
      level: "Other",
      count: "",
      children: [
        {
          firstName: "vikas",
          title: "engineer",
          children: [],
        },
      ],
    },
  ]);


  const onClose = () => {
    setOrgChartDrawerVisible(false);
  };

  // handle changes on filter selectors
  function handleIndustryChange(value) {
    setSelectedIndustry(value);
    //update filter value in search query in context
    let searchQuery = PeopleSearchQuery;
    searchQuery["industry"] = value;
    setPeopleSearchQuery(searchQuery);
  }

  function handleDepartmentChange(value) {
    setSelectedDepartment(value);
    //update filter value in search query in context
    let searchQuery = PeopleSearchQuery;
    searchQuery["department"] = value;
    setPeopleSearchQuery(searchQuery);
  }
  function handleLevelChange(value) {
    setSelectedLevel(value);
    //update filter value in search query in context
    let searchQuery = PeopleSearchQuery;
    searchQuery["seniority"] = value;
    setPeopleSearchQuery(searchQuery);
  }

  function handleCompanySizeChange(value) {
    setSelectedCompanySize(value);
    //update filter value in search query in context
    let searchQuery = PeopleSearchQuery;
    searchQuery["headcount"] = value;
    setPeopleSearchQuery(searchQuery);
  }
  function handleCompanyRevenueChange(value) {
    setSelectedCompanyRevenue(value);
    //update filter value in search query in context
    let searchQuery = PeopleSearchQuery;
    searchQuery["revenue"] = value;
    setPeopleSearchQuery(searchQuery);
  }
  function handleDomainChange(value) {
    setSelectedDomain(value);
    //update filter value in search query in context
    let searchQuery = PeopleSearchQuery;
    searchQuery["companyDomain"] = value;
    setPeopleSearchQuery(searchQuery);
  }

  function handleCompanyChange(value) {
    setSelectedCompany(value);
    //update filter value in search query in context
    let searchQuery = PeopleSearchQuery;
    searchQuery["companyName"] = value;
    setPeopleSearchQuery(searchQuery);
  }
  function handleNameChange(value) {
    setSelectedName(value);
    //update filter value in search query in context
    let searchQuery = PeopleSearchQuery;
    searchQuery["fullName"] = value;
    setPeopleSearchQuery(searchQuery);
  }

  function handleTitleChange(value) {
    setSelectedTitle(value);
    //update filter value in search query in context
    let searchQuery = PeopleSearchQuery;
    searchQuery["title"] = value;
    setPeopleSearchQuery(searchQuery);
  }

  function handleCountryChange(value) {
    setSelectedCountry(value);
    //update filter value in search query in context
    let searchQuery = PeopleSearchQuery;
    searchQuery["country"] = value;
    setPeopleSearchQuery(searchQuery);
  }

  //no use
  function handleFirstNameChange(value) {
    setSelectedFirstName(value);
  }
  function handleLastNameChange(value) {
    setSelectedLastName(value);
  }
  function handleResultLimitChange(value) {
    setResultLimit(value);
  }

  // handle search methods
  const handleDomainSearch = async (value) => {
    await autocompleteCompany(value);
  };

  // Use clearbit autocomplete for company-name, domain & logo
  const autocompleteCompany = async (searchKeyword) => {
    console.log(searchKeyword);
    try {
      if (searchKeyword?.length > 0) {
        await axios
          .get(`${clearbitAutocompleteAPIUrl}?query=${searchKeyword}`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            console.log(response.data);
            setFetchedDomainOption(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  //clears selected filters at once
  const clearFilters = async () => {
    //empty saved context filter
    setPeopleSearchQuery({
      companyName: [],
      companyDomain: [],
      industry: [],
      headcount: [],
      revenue: [],
      country: [],
      title: [],
      seniority: [],
      department: [],
      fullName: [],
    });
    setSelectedCompany([]);
    setSelectedDomain([]);
    setSelectedIndustry([]);
    setSelectedCompanySize([]);
    setSelectedCompanyRevenue([]);
    setSelectedTitle([]);
    setSelectedLevel([]);
    setSelectedDepartment([]);
    setSelectedName([]);
    setSelectedCountry([]);
  };

  const fetchNextPage = async () => {
    // if no next page stored locally then fetch new page from backend

    //if data is available on next page local move to it
    let nextPageContent = paginationData.slice(
      (pageNumber + 1) * resultLimit,
      (pageNumber + 1) * resultLimit + resultLimit
    );

    // console.log(nextPageContent);

    if (nextPageContent.length > 0) {
      // console.log("data available on next page");
      setPageNumber(pageNumber + 1);
      // console.log(pageNumber + 1);
    } else {
      //else fetch new data from backend
      // console.log("fetch new data");
      let fetchNext = true;
      await fetchSearchResult(
        selectedDepartment,
        selectedLevel,
        selectedIndustry,
        selectedCompany,
        selectedCompanySize,
        selectedCompanyRevenue,
        selectedName,
        selectedFirstName,
        selectedLastName,
        selectedTitle,
        selectedDomain,
        selectedCountry,
        titleExactMatch,
        cursorMark,
        resultLimit,
        fetchNext
      );

      //increment page number
      setPageNumber(pageNumber + 1);
    }
  };


  // go to previous page using local persisted data
  const previousPage = () => {
    if (pageNumber >= 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  // go to a specific page number using local persisted data
  const moveToPage = (pageToMove) => {
    //check if there is page data & we are not already on requested page
    if (pageToMove >= 0) {
      setPageNumber(pageToMove);
    }
  };

  const viewAllSelectedContacts = async (selectedUserData) => {
    let purchaseResponse;

    let currentDataOnTable = paginationData.slice(
      pageNumber * resultLimit,
      pageNumber * resultLimit + resultLimit
    );

    let purchasePromises = selectedUserData.map((userData) => {
      let indexOfThisElementInSearchResult = currentDataOnTable.indexOf(userData);

      let params = {
        contactId: userData.id,
        firebaseId: UserId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        companyName: userData.companyName,
        domain: userData.primaryDomain,
        country: userData.country,
        linkedInProfileUrl: userData.linkedInId,
      };

      return purchaseContact(indexOfThisElementInSearchResult, params, "multiple");
    });

    setLoading(true);

    Promise.all(purchasePromises).then(() => {
      console.log("purchase response received!!")
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });


    if (purchaseResponse === "failed") {
      message.info("Don't have enough Contact credits");
    }
  };

  return (
    <>
      <h1>Company search</h1>
      <div className="search-cards-container">
        {/** Search filter card */}
        <div className="search-filter-card">
          {/**clear filter button appear when any filter selected */}
          <div className="filter-header-cta-container">
            {selectedDepartment.length > 0 ||
              selectedLevel.length > 0 ||
              selectedIndustry.length > 0 ||
              selectedCompanySize.length > 0 ||
              selectedCompanyRevenue.length > 0 ||
              selectedName.length > 0 ||
              selectedFirstName.length > 0 ||
              selectedLastName.length > 0 ||
              selectedTitle.length > 0 ||
              selectedDomain.length > 0 ||
              selectedCompany.length > 0 ||
              selectedCountry.length > 0 ? (
              <>
                <button
                  className="secondary-button-active"
                  style={{ margin: "-20px 10px 10px 0px" }}
                  onClick={() => {
                    clearFilters();
                  }}
                >
                  Clear
                </button>
                <Divider style={{ marginTop: "0px" }} />
              </>
            ) : (
              ""
            )}
          </div>

          <h1 className="filter-heading">COMPANY</h1>
          <span className="search-filter-container">
            <span className="filter-label-container">
              <OfficeBuildingIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Company name</h1>
            </span>
            <Select
              bordered={false}
              mode="tags"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Enter company name"
              onChange={handleCompanyChange}
              onSearch={handleDomainSearch}
              value={[...selectedCompany]}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
              listItemHeight={10}
              listHeight={500}
              width={500}
            >
              {fetchedDomainOption.map((company) => (
                <Option value={company.name} label={company.name}>
                  <div
                    className="company-filter-option"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "40px",
                        background: "#f6f6f6",
                        marginRight: "20px",
                      }}
                      src={company.logo}
                      alt=""
                    />
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <p
                        style={{
                          color: "#151417",
                          lineHeight: "13px",
                          fontSize: "12px",
                          margin: "1px",
                          fontWeight: "500",
                        }}
                      >
                        {company.name}
                      </p>
                      <p
                        style={{
                          color: "#8b8b8b",
                          lineHeight: "13px",
                          fontSize: "12px",
                          margin: "1px",
                          fontWeight: "500",
                        }}
                      >
                        {company.domain}
                      </p>
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <LinkIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Company website</h1>
            </span>
            <Select
              bordered={false}
              mode="tags"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Enter company website"
              onChange={handleDomainChange}
              onSearch={handleDomainSearch}
              value={[...selectedDomain]}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
              listItemHeight={10}
              listHeight={500}
              width={500}
            >
              {fetchedDomainOption.map((company) => (
                <Option value={company.domain} label={company.domain}>
                  <div
                    className="company-filter-option"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "40px",
                        background: "#f6f6f6",
                        marginRight: "20px",
                      }}
                      src={company.logo}
                      alt=""
                    />
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <p
                        style={{
                          color: "#151417",
                          lineHeight: "13px",
                          fontSize: "12px",
                          margin: "1px",
                          fontWeight: "500",
                        }}
                      >
                        {company.name}
                      </p>
                      <p
                        style={{
                          color: "#8b8b8b",
                          lineHeight: "13px",
                          fontSize: "12px",
                          margin: "1px",
                          fontWeight: "500",
                        }}
                      >
                        {company.domain}
                      </p>
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
          </span>

          {/* <span className="search-filter-container">
               <span className="filter-label-container">
                 <LibraryIcon className="filter-icons" color={"#4659ff"} />
                 <h1 className="filter-type">Industry</h1>
               </span>
               <Select
                 bordered={false}
                 mode="multiple"
                 tagRender={customSelectTagUI}
                               optionLabelProp="label"
                 allowClear
                 placeholder="Select industry"
                 onChange={handleIndustryChange}
                 style={{ width: "100%", margin: "5px 0px 0px 5px" }}
               >
                 {industryOptions}
               </Select>
             </span> */}

          <span className="search-filter-container">
            <span className="filter-label-container">
              <LibraryIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Industry</h1>
            </span>
            <Cascader
              bordered={false}
              multiple
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Select industry"
              style={{
                width: "100%",
                margin: "5px 0px 0px 5px",
              }}
              options={industryCascaderOptions}
              onChange={handleIndustryChange}
              value={selectedIndustry}
              showSearch={(inputValue, path) =>
                path.some(
                  (option) =>
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) > -1
                )
              }
            />
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <UserGroupIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Headcount</h1>
            </span>

            <Select
              bordered={false}
              mode="multiple"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Select employee headcount"
              onChange={handleCompanySizeChange}
              value={selectedCompanySize}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            >
              {companySizeOptions}
            </Select>
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <TrendingUpIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Revenue</h1>
            </span>
            <Select
              bordered={false}
              mode="multiple"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Select company revenue"
              onChange={handleCompanyRevenueChange}
              value={selectedCompanyRevenue}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            >
              {companyRevenueOptions}
            </Select>
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <LocationMarkerIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Country</h1>
            </span>
            <Select
              bordered={false}
              mode="multiple"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Enter country of company"
              onChange={handleCountryChange}
              value={selectedCountry}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            >
              {countryOptions}
            </Select>
          </span>

          {/** custom people filters */}
          <Divider />
          <h1 className="filter-heading">PEOPLE</h1>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <BriefcaseIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Title</h1>
            </span>
            <Select
              bordered={false}
              mode="tags"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Enter title"
              onChange={handleTitleChange}
              value={selectedTitle}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            />
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <StarIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Seniority</h1>
            </span>
            <Select
              bordered={false}
              mode="multiple"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Select seniority"
              onChange={handleLevelChange}
              value={selectedLevel}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            >
              {levelOptions}
            </Select>
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <UsersIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Department</h1>
            </span>

            <Select
              bordered={false}
              mode="multiple"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Select department"
              onChange={handleDepartmentChange}
              value={selectedDepartment}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            >
              {departmentOptions}
            </Select>
          </span>

          <span className="search-filter-container">
            <span className="filter-label-container">
              <UserIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Full name</h1>
            </span>
            <Select
              bordered={false}
              mode="tags"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Enter full name"
              onChange={handleNameChange}
              value={selectedName}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            />
          </span>

          <span className="search-filter-container" style={{ display: "none" }}>
            <span className="filter-label-container">
              <UserIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">First name</h1>
            </span>
            <Select
              bordered={false}
              mode="tags"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Enter first name"
              onChange={handleFirstNameChange}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            />
          </span>

          <span className="search-filter-container" style={{ display: "none" }}>
            <span className="filter-label-container">
              <UserIcon className="filter-icons" color={"#4659ff"} />
              <h1 className="filter-type">Last name</h1>
            </span>
            <Select
              bordered={false}
              mode="tags"
              tagRender={customSelectTagUI}
              optionLabelProp="label"
              allowClear
              placeholder="Enter last name"
              onChange={handleLastNameChange}
              style={{ width: "100%", margin: "5px 0px 0px 5px" }}
            />
          </span>
        </div>

        {/** Search result card */}
        <div className="search-result-card" id="people-search-result-card">
          <div className="search-result-card-controls">
            {totalContactsAvailable ? (
              <p
                className="available-contact-count"
                style={{ display: "block" }}
              >
                {totalContactsAvailable} contacts
              </p>
            ) : (
              ""
            )}

            <CSVLink
              data={selectedUserData}
              headers={CSVHeaders}
              filename={"leadzilla-console-export.csv"}
              className="export-csv-button"
              target="_blank"
            >
              Export CSV
            </CSVLink>

            <button
              className="primary-button"
              style={{ margin: "0px 20px" }}
              onClick={() => {
                viewAllSelectedContacts(selectedUserData);
              }}
            >
              View Selected
            </button>

            <button
              className="primary-button"
              style={{ margin: "0px 20px", display: "none" }}
              onClick={() => {
                let leadData = {};
                saveContactOnOutreach(leadData);
              }}
            >
              Outreach Test
            </button>

            <button
              className="primary-button"
              style={{ margin: "0px 20px", display: "none" }}
              onClick={async () => {
                await performInBulk();
              }}
            >
              firestoreBulkChange
            </button>

            <Select
              bordered={true}
              defaultValue={15}
              onChange={handleResultLimitChange}
              style={{ width: "80px", margin: "0px 10px 0px 5px" }}
            >
              {resultFetchLimitOptions}
            </Select>

            <button
              className="secondary-button-inactive"
              style={{ margin: "0px 2px" }}
              onClick={() => {
                previousPage();
              }}
            >
              Prev
            </button>

            <button
              className="secondary-button-inactive"
              style={{ margin: "0px 2px" }}
              onClick={() => {
                fetchNextPage();
              }}
            >
              Next
            </button>
          </div>

          <Table
            headers={false}
            style={{ padding: "0px 30px" }}
            size="large"
            columns={columns}
            loading={{
              indicator: (
                <LoadingOutlined
                  style={{
                    fontSize: 40,
                    fontWeight: 600,
                  }}
                />
              ),
              spinning: loading,
            }}
            rowKey="id"
            rowSelection={{ ...rowSelection }}
            // dataSource={[...searchResult]}
            dataSource={paginationData.slice(
              pageNumber * resultLimit,
              pageNumber * resultLimit + resultLimit
            )}
            // pagination={{ pageSize: 100 }}
            pagination={false}
            scroll={{ y: "max-content" }}
          />

          {
            paginationData.length > 0 &&
            <CustomPagination totalPages={
              Math.ceil(paginationData.length / resultLimit)
            }
              activePage={pageNumber}
              prevFunc={previousPage}
              nextFunc={fetchNextPage}
              moveToPage={moveToPage}
            />
          }

          {/* <Pagination
            current={pageNumber + 1}
            total={paginationData.length}
            defaultPageSize={resultLimit}
            // pageSize={resultLimit}
            disabled={paginationData?.length === 0 ? true : false}
            style={{ marginTop: "50px", marginLeft: "50px" }}
            onChange={(page, pageSize) => {
              console.log("page....", page);
              moveToPage(page - 1);
            }}
          /> */}
        </div>

        <Drawer
          title={"Org Chart"}
          placement="right"
          size={"large"}
          onClose={onClose}
          visible={orgChartDrawerVisible}
          extra={
            <Space>{/* <p>Tip: Use drag-drop and zoom gesture </p> */}</Space>
          }
        >
          {/* <Orgchart /> */}
          <Table
            showHeader={false}
            pagination={false}
            dataSource={testDataSource}
            columns={testColumns}
          />
          <Tree
            defaultSelectedKeys={["0-0-0"]}
            showLine={true}
            showIcon={false}
            treeData={treeData}
            onSelect={onOrgchartNodeSelect}
            selectedKeys={selectedOrgchartNodeKeys}
            titleRender={(treeNode) => {
              return (
                <>
                  <span
                    className="title"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px 10px",
                      border: "1px solid #e2e2e2",
                      borderRadius: "5px",
                      minHeight: "50px",
                      minWidth: "200px",
                      boxShadow: "0px 0px 10px 5px rgba(200, 200, 200, 0.2)",
                    }}
                  >
                    <h4 className="contact-name">{treeNode.fullName}</h4>
                    <span className="contact-role">{treeNode.title}</span>
                    <span className="contact-role">{treeNode.level}</span>
                    <span className="contact-name">{treeNode.count}</span>

                    {treeNode.linkedInId ? (
                      <a
                        href={"http://" + treeNode.linkedInId}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        LinkedIn
                      </a>
                    ) : (
                      ""
                    )}
                  </span>
                </>
              );
            }}
          />
        </Drawer>
      </div>
      <Modal
        title=""
        visible={fullEmailModal}
        footer={null}
        maskClosable={false}
        centered
        onCancel={() => setFullEmailModal(false)}
      >
        <h2 className="emailHeader">Full Email</h2>
        <div className="emailWrapper">
          <span><b>{fullEmail.subject}</b></span>
          <textarea className="emailBody" value={fullEmail.body} onChange={(e) => handleEmailEdit(e)} />
        </div>
        <div className="mailModalButtonWrapper">
          <Button
            loading={copyLoading}
            onClick={() => copyTextToClipboard(fullEmail)}
            className="secondary-button-active"
          >
            {copyButtonText}
          </Button>
          <Button
            loading={emailRewriteLoading}
            onClick={() => generateEmail()}
            className="secondary-button-active"
          >
            {regenerateButtonText}
          </Button>
        </div>
      </Modal>
    </>
  );
}
